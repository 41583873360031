import {Header} from "../components/Header";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {isIOS, isPc, sendRequest} from "../static/js/common";
import 'rc-dropdown/assets/index.css';


export const Nav = ({}) => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [brochureUrl, setBrochureUrl] = useState("");
  const [adUrl, setAdUrl] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");

  const getBrochure = () => {
    sendRequest('config/config/?key=BROCHURE', 'GET', null, data => {
      setBrochureUrl(data?.[0]?.file)
    })
  }

  const getUrls = () => {
    sendRequest('config/config/?key=광고주URL', 'GET', null, data => {
      setAdUrl(data?.[0]?.value)
    })
    sendRequest('config/config/?key=매체사URL', 'GET', null, data => {
      setMediaUrl(data?.[0]?.value)
    })
  }

  useEffect(() => {
    getBrochure()
    getUrls()
  }, [])

  useEffect(() => {
    // setTimeout(() => {
    //   setLoading(false)
    //   window.scrollTo(0,10);
    // }, 500)
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    <div>
      <Header/>
      {isPc ?
        <div className={""} style={{marginTop: 80}}>
          <video playsInline muted autoPlay style={{height: 'calc(100vh - 80px)', minWidth: window.innerWidth, objectFit: 'cover'}}
                 src={require('../assets/videos/main_01_1.mp4')}/>
          {!loading &&
            <div className={'absolute'} style={{top: 0, left: 0, padding: '8vw', paddingBottom: 0, paddingLeft: '12vw', height: 'calc(100vh - 160px)'}}>
              <div className={'flex-row'} style={{height: '80vh'}}>
                  <div className={'relative cursor'} onClick={() => navigate('/ad-product')}>
                    <img style={{width: '24vw'}} src={require('../assets/images/nav_adproduct.png')}/>
                    <div className={'absolute width-100 flex-center flex-column'}
                         style={{top: 0, left: 0, height: '100%'}}>
                      <div className={'poppins-black white'} style={{fontSize: '3.5vw'}}>AD PRODUCT</div>
                      <div className={'notosans-regular white'} style={{fontSize: '1vw'}}>Targeting / Interplay / DA /
                        Reference
                      </div>
                    </div>
                  </div>
                  <div className={'relative cursor'} onClick={() => navigate('/publisher')} style={{marginLeft: '1vw'}}>
                    <img style={{width: '24vw'}} src={require('../assets/images/nav_publisher.png')}/>
                    <div className={'absolute width-100 flex-center flex-column'}
                         style={{top: 0, left: 0, height: '100%'}}>
                      <div className={'poppins-black white'} style={{fontSize: '3.5vw'}}>PUBLISHER</div>
                      <div className={'notosans-regular white'} style={{fontSize: '1vw'}}>Optimization / Google
                        Partnership /
                        Client
                      </div>
                    </div>
                  </div>
                <div className={'flex-column'} style={{marginLeft: '1vw'}}>
                    <div className={'relative cursor'} onClick={() => navigate('/career')}>
                      <img style={{width: '24vw'}} src={require('../assets/images/nav_career.png')}/>
                      <div className={'absolute width-100 flex-center flex-column'}
                           style={{top: 0, left: 0, height: '100%'}}>
                        <div className={'poppins-black white'} style={{fontSize: '2vw'}}>CAREERS</div>
                        <div className={'notosans-regular white'} style={{fontSize: '1vw'}}>인터웍스에 지원하기</div>
                      </div>
                    </div>
                    <div className={'relative cursor'} style={{marginTop: '1vw'}}>
                      <img style={{width: '24vw'}} src={require('../assets/images/nav_case.png')}/>
                      <div className={'absolute width-100 flex-center flex-column'}
                           style={{top: 0, left: 0, height: '100%'}} onClick={() => window.open(brochureUrl)}>
                        <div className={'poppins-black white'} style={{fontSize: '2vw'}}>CREDENTIAL</div>
                        <div className={'notosans-regular white'} style={{fontSize: '1vw'}}>회사소개서 다운로드</div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          }
        </div>
        :
        <div className={'relative black-bg'} style={{marginTop: 80, overflow: 'visible'}}>
          {/*<video playsInline muted autoPlay className={'absolute'} style={{height: height + 80, top: 0, left: 0, zIndex: 0, minWidth: window.innerWidth, objectFit: 'cover'}}*/}
          {/*       src={require('../assets/videos/m_main_01.mp4')}/>*/}
          {!loading &&
            <div className={'relative'}>
              {isIOS() ?
                <div className={'relative flex-center cursor'} onClick={() => navigate('/ad-product')}>
                  <img style={{width: '90vw', marginLeft: '5vw'}}
                       src={require('../assets/images/mobile/nav_adproduct.png')}/>
                </div>
                :
                <div className={'relative flex-center cursor'} onClick={() => navigate('/ad-product')}>
                  <img style={{width: '90vw', marginLeft: '5vw'}}
                       src={require('../assets/images/mobile/nav_adproduct.png')}/>
                </div>
              }
              <div className={'relative flex-center cursor'} onClick={() => navigate('/publisher')}>
                <img style={{width: '90vw', marginLeft: '5vw', marginTop: '2vw'}}
                     src={require('../assets/images/mobile/nav_publisher.png')}/>
              </div>
              <div className={'flex-row'} style={{marginLeft: '5vw', marginTop: '2vw'}}>
                <div className={'relative cursor'} onClick={() => navigate('/career')}>
                  <img style={{width: '44vw'}} src={require('../assets/images/mobile/nav_career.png')}/>
                </div>
                <div className={'relative cursor'} style={{marginLeft: '2vw'}} onClick={() => window.open(brochureUrl)}>
                  <img style={{width: '44vw'}} src={require('../assets/images/mobile/broschure.png')}/>
                </div>
              </div>
              <div className={'margin-top-32 flex-row'} style={{paddingLeft: '5vw'}}>
                {/*<div className={'poppins-black white font-24'} onClick={() => navigate('/career')}>CAREER &nbsp;<span*/}
                {/*  className={'notosans-bold font-10'}>인터웍스 지원하기</span>*/}
                {/*</div>*/}
                {/*<div className={'poppins-black white font-24 margin-top-12'} onClick={() => window.open(brochureUrl)}>CREDENTIAL &nbsp;<span*/}
                {/*  className={'notosans-bold font-10'}>회사소개서 다운로드</span>*/}
                {/*</div>*/}
                <div className={'poppins-black white font-24 margin-top-12 flex-1 flex-row'}
                     onClick={() => navigate('/contact')}>Contact
                  Us &nbsp;<span className={'notosans-bold font-10'}>문의하기</span>
                </div>
                <div className={'flex-end'}>
                  <div className={'select-box cursor notosans-regular flex-row font-14'} style={{marginLeft: '0.5vw'}}>
                    <select
                      className={'select font-14 cursor'}
                      defaultValue={'리포트'}
                      onChange={(e) => window.open(e.target.value)}
                    >
                      <option disabled={true}>
                        리포트
                        <img className={'icon-12 margin-left-8'} src={require('../assets/images/arrow_down.png')}/>
                      </option>
                      <option value={adUrl}>광고주</option>
                      <option value={mediaUrl}>매체사</option>
                    </select>
                    {/*<Dropdown*/}
                    {/*  trigger={['contextMenu', 'click']}*/}
                    {/*  overlay={menu}*/}
                    {/*  animation="slide-up"*/}
                    {/*  alignPoint*/}
                    {/*>*/}
                    {/*  <div>*/}
                    {/*    리포트<img className={'icon-12 margin-left-8'} src={require('../assets/images/arrow_down.png')}/>*/}
                    {/*  </div>*/}
                    {/*</Dropdown>*/}
                  </div>
                </div>

              </div>

              <div className={'flex-row margin-top-12'} style={{marginLeft: '5vw'}}>
                {/*<div className={''} style={{paddingRight: '2vw'}}>*/}
                {/*  <div className={'brochure'}>COMPANY BROCHURE</div>*/}
                {/*</div>*/}

              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}
