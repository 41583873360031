


export const TextInput = ({title, placeholder, value, titleFontSize=14, titleMarginBottom=8, setValue=()=>{}, backgroundColor, width, height, fontSize, padding, placeholderColor, border, color, borderRadius=0, multiline=false, bottomComponent=null}) => {

  return (
    <div className={'text-input-wrapper relative'}>
      <div className={'blur-white font-14 notosans-regular'} style={{fontSize: titleFontSize, marginBottom: titleMarginBottom}}>{title}</div>
      {multiline ?
        <textarea className={`text-input notosans-regular ${placeholderColor}`}
               style={{borderRadius, backgroundColor, width, height, fontSize, padding, borderColor: backgroundColor ? '#808080' : null, border: 'none', color: color ? color : backgroundColor ? '#424242' : '#fff'}} value={value}
               onChange={e => setValue(e.target.value)} placeholder={placeholder}/>
        :
        <input className={`text-input notosans-regular ${placeholderColor}`}
               style={{borderRadius, backgroundColor, width, height, fontSize, padding, borderColor: backgroundColor ? '#808080' : null, border, color: color ? color : backgroundColor ? '#424242' : '#fff'}} value={value}
               onChange={e => setValue(e.target.value)} placeholder={placeholder}/>
      }
      {bottomComponent && bottomComponent}
    </div>
  )
}
