import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {TextInput} from "../modules/TextInput";
import {Button} from "../modules/Button";
import {useEffect, useRef, useState} from "react";
import {isPc, SCREEN_WIDTH, sendFormRequest, sendRequest, validateEmail, validatePhone} from "../static/js/common";
import {Marker} from "./Main";
import GoogleMapReact from 'google-map-react';
import {ContactTextInput} from "../modules/ContactTextInput";
import {useNavigate} from "react-router-dom";


const FileInput = ({fileRef, setFile}) => {
  const [filename, setFilename] = useState("")

  return (
    <div className={'flex-row cursor'}  onClick={() => fileRef?.current?.click()}>
      <div className={'contact-file-box'}>
        <input ref={fileRef} type={'file'} accept={'image/jpg,image/png,application/pdf'} style={{display: 'none'}} onChange={e => {
          const file_ = e?.target.files[0]
          if (file_?.size > 50 * 1024 * 1024) {
            alert('파일 용량은 50MB 이하로 업로드 해주세요.')
            return
          }
          setFile(file_)
          setFilename(file_?.name)
        }}/>
        <img className={'icon-48'} src={require('../assets/images/attachment.png')}/>
      </div>
      <div className={'white font-20 margin-left-20 file-text'} style={{marginTop: -6}}>{filename?.length ? filename : '50MB 이하의 .jpg, .png, .pdf 파일 업로드 가능'}</div>
    </div>
  )
}


const MobileFileInput = ({fileRef, setFile}) => {
  const [filename, setFilename] = useState("")

  return (
    <div className={'flex-column align-center'} onClick={() => fileRef?.current?.click()}>
      <div className={'contact-file-box'}>
        <input ref={fileRef} type={'file'} accept={'image/jpg,image/png,application/pdf'} style={{display: 'none'}} onChange={e => {
          const file_ = e?.target.files[0]
          if (file_?.size > 50 * 1024 * 1024) {
            alert('파일 용량은 50MB 이하로 업로드 해주세요.')
            return
          }
          setFile(file_)
          setFilename(file_?.name)
        }}/>
        <img className={'icon-48'} src={require('../assets/images/attachment.png')}/>
      </div>
      <div className={'white font-12 margin-top-16'}>{filename?.length ? filename : '50MB 이하의 .jpg, .png, .pdf 파일 업로드 가능'}</div>
    </div>
  )
}


export const Contact = ({}) => {

  const defaultProps = {
    center: {
      lat: 37.508181,
      lng: 127.062797
    },
    zoom: 15
  };

  const customerTypes = [
    '광고주/대행사',
    '매체제휴',
    '기타'
  ]

  const navigate = useNavigate();

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [organization, setOrganization] = useState("")
  const [email, setEmail] = useState("")
  const [customerType, setCustomerType] = useState(customerTypes[0])
  const [agreePrivacy, setAgreePrivacy] = useState(false)
  const [file, setFile] = useState("")
  const fileRef = useRef()
  const [agreePrivacyOnCareerTitle, setAgreePrivacyOnCareerTitle] = useState('')
  const [agreePrivacyOnCareer, setAgreePrivacyOnCareer] = useState('')

  const saveContact = () => {
    if (!title) {
      alert('프로젝트 명을 입력해 주세요.')
      return
    }

    if(!validatePhone(phone)) {
      alert('연락처는 숫자만 입력해 주세요.')
      return
    }

    if (!email || !validateEmail(email)) {
      alert('올바른 이메일을 입력해 주세요.')
      return
    }

    if (!agreePrivacy) {
      alert('개인정보보호정책에 동의해 주세요.')
      return
    }

    sendRequest('contact/contact/', 'POST', {
      title,
      description,
      name,
      phone,
      organization,
      email,
      customerType,
      agreePrivacy,
    }, data => {
      if (file?.size) {
        const formData = new FormData();
        formData.append('file', file)
        sendFormRequest(`contact/contact-file/${data?.id}/`, 'POST', formData, data => {
          navigate(-1)
        })
      } else {
        navigate(-1)
      }
    })

    alert('프로젝트 의뢰가 올바르게 신청 되었습니다.')
  }

  const getConfig = () => {
    sendRequest('config/config/?key=agreePrivacyOnContact', 'GET', null, data => {
      const agreement = data[0]
      setAgreePrivacyOnCareerTitle(agreement?.value)
      setAgreePrivacyOnCareer(agreement?.desc)
    })
  }

  useEffect(() => {
    window.scroll(0, 0)
    getConfig()
  }, []);

  return (
    <div className={'flex-1 flex-column'}>
      {/*<Header/>*/}
      {isPc ?
        <div className={'pc-container relative flex-column flex-center light-black-bg'} style={{marginTop: 0}}>
          <div className={'absolute cursor'} style={{right: 20, top: 20}} onClick={() => navigate(-1)}>
            <img className={'icon-32'} src={require('../assets/images/cancel_white.png')}/>
          </div>
          <div className={'flex-row-wo-center'} style={{marginTop: 40}}>
            <div style={{padding: 40}}>
              <div className={'flex-1'} style={{height: 'calc(50% + 3.5vw)', width: 'calc(50vw - 80px)', marginTop: 0}}>
                <GoogleMapReact
                  bootstrapURLKeys={{key: "AIzaSyAF9VXtNJ3iorhFO-wKZvtODU7mX8W12Q0"}}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                >
                  <Marker lat={37.508181} lng={127.062797}/>
                </GoogleMapReact>
              </div>
            </div>
            <div className={'flex-1 width-100 padding-top-40'}>
              <div className={'flex-column'}>
                <div className={'flex-1'} style={{}}>
                  <div className={'contact-wrapper'}>
                    <div className={'white notosans-black font-30 whiter-border-bottom padding-bottom-40'}>문의하기</div>
                    <div className={'white margin-top-32 bold font-22 notosans-bold'}>고객 유형이 어떻게 되시나요?</div>
                    <div className={'flex-row'}>
                      {customerTypes?.map((item, index) => {
                        const isActive = item === customerType
                        return (
                          <div className={'flex-row margin-top-10 cursor'} style={{marginLeft: index === 0 ? 0 : '3vw'}}
                               onClick={() => setCustomerType(item)}>
                            <img className={'icon-14'}
                                 src={isActive ? require('../assets/images/checkbox.png') : require('../assets/images/checkbox_off.png')}/>
                            <div className={'blur-white margin-left-8 font-20'}>{item}</div>
                          </div>
                        )
                      })}
                    </div>

                    <div className={'margin-top-32'}>
                      <TextInput
                        title={'제목'}
                        titleFontSize={20}
                        titleMarginBottom={0}
                        // padding={'10px 20px'}
                        fontSize={20}
                        value={title}
                        setValue={setTitle}
                        color={'#fff'}
                        width={'calc(100% + 35px)'}
                      />
                    </div>
                    <div className={'margin-top-20'}>
                      <TextInput
                        title={''}
                        placeholder={'내용'}
                        height={334}
                        multiline={true}
                        padding={'20px'}
                        fontSize={20}
                        value={description}
                        setValue={setDescription}
                        color={'#555'}
                        placeholderColor={'dark-placeholder'}
                        backgroundColor={'#fff'}
                      />
                    </div>

                    <div className={'margin-top-32 file-input-wrapper align-center'}>
                      <FileInput setFile={setFile} fileRef={fileRef}/>
                    </div>

                    <div className={'margin-top-48'}>
                      <div className={'flex-row'}>
                        <div className={'flex-1'}>
                          <TextInput title={'이름'} titleFontSize={20} titleMarginBottom={0} fontSize={20}
                                     padding={'0 40px 10px 0'} value={name}
                                     setValue={setName}/>
                        </div>
                        <div className={'flex-1'} style={{marginLeft: 80}}>
                          <TextInput title={'연락처'} titleFontSize={20} titleMarginBottom={0} fontSize={20}
                                     padding={'0 40px 10px 0'} value={phone}
                                     setValue={setPhone}/>
                        </div>
                      </div>
                    </div>
                    <div className={'margin-top-32'}>
                      <div className={'flex-row'}>
                        <div className={'flex-1'}>
                          <TextInput title={'회사'} titleFontSize={20} titleMarginBottom={0} fontSize={20}
                                     padding={'0 40px 10px 0'} value={organization}
                                     setValue={setOrganization}/>
                        </div>
                        <div className={'flex-1'} style={{marginLeft: 80}}>
                          <TextInput title={'이메일'} titleFontSize={20} titleMarginBottom={0} fontSize={20}
                                     padding={'0 40px 10px 0'} value={email}
                                     setValue={setEmail}/>
                        </div>
                      </div>
                    </div>

                    {/*<div className={'flex-center margin-top-32'} align={'center'}>*/}
                    {/*  <div className={'flex-row'}*/}
                    {/*       style={{padding: '20px 32px', borderRadius: 64, backgroundColor: '#3F3F3F'}}*/}
                    {/*       onClick={() => fileRef?.current?.click()}>*/}
                    {/*    <input ref={fileRef} type={'file'} accept={'application/pdf'} style={{display: 'none'}}*/}
                    {/*           onChange={e => {*/}
                    {/*             const file_ = e?.target.files[0]*/}
                    {/*             if (file_?.size > 50 * 1024 * 1024) {*/}
                    {/*               alert('파일 용량은 50MB 이하로 업로드 해주세요.')*/}
                    {/*               return*/}
                    {/*             }*/}
                    {/*             setFile(file_)*/}
                    {/*           }}/>*/}
                    {/*    <div className={'file-box flex-center'} style={{maxWidth: 69, backgroundColor: '#7F7F7F'}}>*/}
                    {/*      <img className={'icon-24'} src={require('../assets/images/attachment.png')}/>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*      className={'white font-20 margin-left-20'}>{file?.size ? file?.name : '업무요청서 또는 소개서 업로드 부탁드립니다.'}</div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className={'margin-top-32'}>
                      <div className={'flex-1 flex-column align-start'}>
                        <div className={'flex-row cursor'} onClick={() => setAgreePrivacy(!agreePrivacy)}>
                          <img className={'icon-22'}
                               src={agreePrivacy ? require('../assets/images/checkbox_white.png') : require('../assets/images/check_gray.png')}/>
                          <div className={`${agreePrivacy ? 'white' : 'blur-white'} bold font-20 margin-left-16`}>
                            <span className={'underline'}>{agreePrivacyOnCareerTitle}</span>
                          </div>
                        </div>
                        <div className={'more-blur-white contact-agreement pre-wrap black-bg'}
                             style={{width: 'calc(100%)'}}>
                          {agreePrivacyOnCareer}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'flex-center'} style={{marginTop: 80, marginBottom: 80}}>
            <Button text={'작성 완료'} width={240} height={68} color={'#fff'} backgroundColor={'#404040'}
                    borderRadius={68} onClick={saveContact} icon={<img className={'icon-24'} style={{marginTop: 8}} src={require('../assets/images/checkbox_white.png')}/>} />
          </div>

          {/*<div className={'fixed'} style={{right: 27, bottom: 20, zIndex: 99, opacity: 0.8}}*/}
          {/*     onClick={() => window?.scrollTo(0, 0)}>*/}
          {/*  <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>*/}
          {/*</div>*/}
        </div>
        :
        <div className={'mobile-container flex-center mobile-wrapper light-black-bg'}>
          <div className={'absolute'} style={{right: 20, top: 20}} onClick={() => navigate(-1)}>
            <img className={'icon-32'} src={require('../assets/images/cancel_white.png')}/>
          </div>
          <div className={'flex-1 align-center width-100'} style={{marginTop: 40, backgroundColor: '#252525'}}>
            <div className={'flex-column'} style={{width: '100%'}}>
              <div className={'padding-20'}>
                <div className={'white notosans-black font-30 whiter-border-bottom padding-bottom-40'}>문의하기</div>
              </div>
              <div className={'flex-1 align-center'} style={{marginTop: 0}}>
                <div className={'mobile-contact-wrapper relative'}>
                  <div className={'white margin-top-12 bold font-16 notosans-black'}>고객 유형이 어떻게 되시나요?</div>
                  <div className={'flex-column'}>
                    {customerTypes?.map((item, index) => {
                      const isActive = item === customerType
                      return (
                        <div className={'flex-row margin-top-18'}
                             onClick={() => setCustomerType(item)}>
                          <img className={'icon-14'}
                               src={isActive ? require('../assets/images/checkbox.png') : require('../assets/images/checkbox_off.png')}/>
                          <div className={'white margin-left-8 font-16'}>{item}</div>
                        </div>
                      )
                    })}
                  </div>

                  <div className={'margin-top-32'}>
                    <TextInput
                      title={'제목'}
                      titleFontSize={12}
                      titleMarginBottom={8}
                      fontSize={16}
                      width={SCREEN_WIDTH - 45}
                      value={title}
                      setValue={setTitle}
                      color={'#fff'}
                    />
                  </div>
                  <div className={'margin-top-20'}>
                    <TextInput
                      title={''}
                      placeholder={'내용'}
                      height={344}
                      multiline={true}
                      padding={'20px'}
                      fontSize={16}
                      width={SCREEN_WIDTH - 80}
                      value={description}
                      setValue={setDescription}
                      color={'#555'}
                      placeholderColor={'dark-placeholder'}
                      backgroundColor={'#fff'}
                    />
                  </div>

                  <div className={'margin-top-32'}>
                    <div className={'flex-row flex-1'}>
                      <div className={'flex-1'}>
                        <TextInput title={'이름'} titleFontSize={12} titleMarginBottom={8}
                                   fontSize={16} value={name} setValue={setName}
                                   width={(SCREEN_WIDTH - 90) / 2}/>
                      </div>
                      <div className={'flex-1'} style={{marginLeft: 30}}>
                        <TextInput title={'연락처'} titleFontSize={12} titleMarginBottom={8}
                                   fontSize={16} value={phone} setValue={setPhone}
                                   width={(SCREEN_WIDTH - 90) / 2}/>
                      </div>
                    </div>
                  </div>
                  <div className={'margin-top-32'}>
                    <div className={'flex-row'}>
                      <div className={'flex-1'}>
                        <TextInput title={'회사'} titleFontSize={12} titleMarginBottom={8}
                                   fontSize={16} value={organization}
                                   setValue={setOrganization}
                                   width={(SCREEN_WIDTH - 90) / 2}/>

                      </div>
                      <div className={'flex-1'} style={{marginLeft: 30}}>
                        <TextInput title={'이메일'} titleFontSize={12} titleMarginBottom={8}
                                   fontSize={16} value={email} setValue={setEmail}
                                   width={(SCREEN_WIDTH - 90) / 2}/>
                      </div>
                    </div>
                  </div>

                  <div className={'margin-top-40 file-input-wrapper align-center'} style={{width: 'calc(100% - 69px)'}}>
                    <MobileFileInput fileRef={fileRef} setFile={setFile}/>
                  </div>

                  {/*<div className={'flex-center margin-top-32'} align={'center'}>*/}
                  {/*  <div className={'flex-row'}*/}
                  {/*       style={{padding: '20px 32px', borderRadius: 64, backgroundColor: '#3F3F3F'}}*/}
                  {/*       onClick={() => fileRef?.current?.click()}>*/}
                  {/*    <input ref={fileRef} type={'file'} accept={'application/pdf'} style={{display: 'none'}}*/}
                  {/*           onChange={e => {*/}
                  {/*             const file_ = e?.target.files[0]*/}
                  {/*             if (file_?.size > 50 * 1024 * 1024) {*/}
                  {/*               alert('파일 용량은 50MB 이하로 업로드 해주세요.')*/}
                  {/*               return*/}
                  {/*             }*/}
                  {/*             setFile(file_)*/}
                  {/*           }}/>*/}
                  {/*    <div className={'file-box flex-center'} style={{maxWidth: 69, backgroundColor: '#7F7F7F'}}>*/}
                  {/*      <img className={'icon-24'} src={require('../assets/images/attachment.png')}/>*/}
                  {/*    </div>*/}
                  {/*    {file?.size ? <div className={'white font-14 margin-left-20'}>{file?.name}</div>*/}
                  {/*      :*/}
                  {/*      <div className={'white font-14 margin-left-20'}>업무요청서 또는 소개서<br/> 업로드 부탁드립니다.</div>*/}
                  {/*    }*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className={'margin-top-32'}>
                    <div className={'flex-1 flex-column align-start'}>
                      <div className={'flex-row'} onClick={() => setAgreePrivacy(!agreePrivacy)}>
                        <img className={'icon-24'}
                             src={agreePrivacy ? require('../assets/images/checkbox_white.png') : require('../assets/images/check_gray.png')}/>
                        <div className={`${agreePrivacy ? 'white' : 'blur-white'} bold font-16 margin-left-16`}>
                          <span className={'underline'}>{agreePrivacyOnCareerTitle}</span>
                        </div>
                      </div>
                      <div className={'more-blur-white mobile-agreement pre-wrap'}>
                        {agreePrivacyOnCareer}
                      </div>
                    </div>
                  </div>

                  <div className={'flex-center'} style={{marginTop: 38, marginBottom: 50}}>
                    <Button text={'작성 완료'} width={242} height={68} color={'#fff'}
                            backgroundColor={'#404040'}
                            icon={<img className={'icon-24'} style={{marginTop: 8}} src={require('../assets/images/checkbox_white.png')}/>}
                            borderRadius={68} onClick={saveContact}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'fixed cursor'} style={{left: 16, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => window?.scrollTo(0, 0)}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
      }
      <Footer/>
    </div>
  )
}
