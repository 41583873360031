import '../static/css/common.css'
import '../static/css/main.css'
import {Header} from "../components/Header";
import {Fade, Slide} from "react-awesome-reveal";
import ScrollAnimation from 'react-animate-on-scroll';
import {useNavigate} from "react-router-dom";
import {Footer} from "../components/Footer";
import GoogleMapReact from 'google-map-react';
import {
  getDateTime,
  isIOS,
  isPc,
  retrieveData,
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
  sendRequest,
  storeData
} from "../static/js/common";
import {useCallback, useEffect, useRef, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modal from "react-modal";

export const Marker = ({}) => {
  return (
    <div className={'flex-row'} style={{marginTop: -64, marginLeft: -16}}>
      <img style={{width: 30}} src={require('../assets/images/marker.png')} alt="marker"/>
      <div className={'font-16 bold'} style={{minWidth: 200, marginTop: 60, marginLeft: -10}}>인터웍스미디어</div>
    </div>
  )
}

const throttle = (func, delay) => {
  let lastCall = 0;
  return (...args) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
}

export const Main = ({}) => {

  const navigate = useNavigate()
  const carouselRef = useRef(null)
  const scrollRef = useRef(null)
  const video1Ref = useRef(null)
  const video2Ref = useRef(null)
  const video3Ref = useRef(null)
  const video4Ref = useRef(null)

  const [brandImage, setBrandImage] = useState('');
  const [partnershipImage, setPartnershipImage] = useState('');
  const [mobilePartnershipImage1, setMobilePartnershipImage1] = useState('');
  const [mobilePartnershipImage2, setMobilePartnershipImage2] = useState('');

  const [banners, setBanners] = useState([]);
  const [showBanners, setShowBanners] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);

  const getBanners = () => {
    const now = new Date()
    sendRequest(`banner/banner/?isExposure=true&now=${getDateTime(now)}`, 'GET', null, async data => {
      setBanners(data)
      if(data?.length) {
        let notShowUntil = await retrieveData('notShowUntil')
        if (!notShowUntil) {
          setShowBanners(true)
        } else {
          notShowUntil = new Date(notShowUntil)
          if (notShowUntil < now) {
            setShowBanners(true)
          }
        }
      }
    })
  }

  const notShowBanner = () => {
    let notShowUntil = new Date()
    notShowUntil.setDate(new Date().getDate() + 5)
    storeData('notShowUntil', notShowUntil.getTime())
  }

  const getBrandImage = () => {
    sendRequest('adproduct/branding/?key=pc', 'GET', null, data => {
      setBrandImage(data?.[0]?.image)
    })
  }

  const getPartnershipImage = () => {
    sendRequest('publisher/partnership/?key=pc', 'GET', null, data =>{
      setPartnershipImage(data?.[0]?.image)
    })
  }

  const getMobilePartnershipImage = () => {
    sendRequest('publisher/partnership/?key=mobile1', 'GET', null, data =>{
      setMobilePartnershipImage1(data?.[0]?.image)
    })

    sendRequest('publisher/partnership/?key=mobile2', 'GET', null, data =>{
      setMobilePartnershipImage2(data?.[0]?.image)
    })
  }

  const partners = [
    'Publisher Partner',
    '3rd Party Partner'
  ]

  const [partner, setPartner] = useState(partners[0])

  const defaultProps = {
    center: {
      lat: 37.508181,
      lng: 127.062797
    },
    zoom: 15
  };

  const [rotate, setRotate] = useState({ x: 0, y: 0 });

  const onMouseMove = useCallback(
    throttle((e) => {
      const card = e.currentTarget;
      const box = card.getBoundingClientRect();
      const x = e.clientX - box.left;
      const y = e.clientY - box.top;
      const centerX = box.width / 2;
      const centerY = box.height / 2;
      const rotateX = (y - centerY) / 70;
      const rotateY = (centerX - x) / 70;

      setRotate({ x: rotateX, y: rotateY });
    }, 100),
    []
  );

  const onMouseLeave = () => {
    setRotate({ x: 0, y: 0 });
  };

  useEffect(() => {
    getBrandImage()
    if(isPc) {
      getPartnershipImage()
    } else {
      getMobilePartnershipImage()
    }
    getBanners()
  }, [])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '0%',
      width: isPc ? '540px' : '360px',
      height: 'auto',
      maxHeight: isPc ? '580px' : '400px',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#252525',
      zIndex: 9999,
      position: 'relative',
      overflow: 'hidden',
      padding: 0,
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Header scrollRef={scrollRef} openMenu={openMenu}/>
      {isPc ?
        <div ref={scrollRef} className={'pc-container relative scroll-snap height-100vh'}>
          <div className={'relative section'}>
            <img className={'container-img'} src={require('../assets/images/main_01.png')}/>
            {showBanners ? null :
              <div
                // onMouseMove={onMouseMove}
                // onMouseLeave={onMouseLeave}
                className={'absolute width-100 screen'}
                style={{top: -15, left: '5vw', zIndex: 2, paddingTop: '6vh'}}
              >
                <Fade direction={'left'}>
                  <div className={'white poppins-black home-title'} style={{lineHeight: 1.1}}>
                    INTERWORKS MEDIA
                  </div>
                  <div style={{marginTop: '3.5vh'}}>
                    <div className={'white notosans-regular font-24'} style={{lineHeight: 1.6}}>
                      인터웍스미디어는 브랜드에 최적화한 맞춤 솔루션과 고도화된 필터링 기술로<br/>
                      DSP와 SSP를 통합적으로 운용하는 프리미엄 애드 네트워크 플랫폼입니다.
                    </div>
                  </div>
                </Fade>
              </div>
            }
            {showBanners ? null :
              <div className={'absolute'} style={{bottom: '6.5dvh', right: '5vw', zIndex: 9}}>
                <Fade direction={'right'}>
                  <div className={'white notosans-regular text-right font-24'} style={{lineHeight: 1.6}}>
                    정확한 타겟팅과 다양한 상품을 지원하며<br/> 가장 좋은 위치의 지면을 확보하여<br/>
                    효과적인 광고 캠페인 집행이 가능합니다.
                  </div>
                </Fade>
              </div>
            }
            <div className={'absolute width-100'} style={{top: '20vh', left: 0}}
                 onClick={() => navigate('/nav')}>
              <div className={''} align={'center'}>
                {/*<div*/}
                {/*  // onMouseMove={onMouseMove}*/}
                {/*  // onMouseLeave={onMouseLeave}*/}
                {/*  style={{*/}
                {/*    transform: `perspective(1000px) rotateX(${rotate.x}deg) rotateY(${rotate.y}deg) scale3d(1, 1, 1)`,*/}
                {/*    transition: "all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s",*/}
                {/*  }}*/}
                {/*>*/}
                  <video playsInline muted autoPlay className={'screen main-video'} style={{zIndex: 1}}
                         src={require('../assets/videos/main_01_1.mp4')}/>
                {/*</div>*/}
                {showBanners ? null :
                  <div className={'width-100 align-center'}>
                    <div className={'screen-btn poppins-black align-center'} style={{marginTop: '-2vw'}}>DETAIL VIEW</div>
                  </div>
                }
                {/*<img width={47.4} style={{marginTop: 40}} src={require('../assets/images/mouse.png')}/>*/}
              </div>
            </div>
          </div>

          <div className={'relative white-bg section'} style={{marginTop: -5}}>
            <img className={'container-img'} src={require('../assets/images/main2_bg_.png')}/>
            <div className={'absolute width-100 align-center height-100vh'} style={{top: -80, left: 0}}>
              <Fade direction={'left'}>
                <div className={'poppins-black gray text-center en-title'} style={{lineHeight: 1.1}}>
                  Premium<br/>
                  AD Network Platform
                </div>
              </Fade>
            </div>
            <div className={'absolute width-100 align-center'} style={{bottom: '32vh', left: 0}}>
              <Fade direction={'right'}>
                <div className={'notosans-regular text-center font-24 gray'} style={{lineHeight: 1.6}}>
                  애드 네트워크 플랫폼은 개별적으로 존재했던 각 사이트의<br/>
                  인벤토리를 하나의 네트워크로 통합하고<br/>
                  광고의 효율적인 퍼포먼스 및 최적화 관리가 가능한 플랫폼입니다.
                </div>
              </Fade>
            </div>
          </div>

          <div className={'relative flex-row white-bg section'} style={{marginTop: 0}}>
            <div
              className={'flex-1 relative'}
              onMouseOver={event => video1Ref?.current?.play()}
              onMouseOut={event => video1Ref?.current?.pause()}
            >
              <video
                ref={video1Ref}
                className={'vertical-video'}
                muted
                playsInline
                poster={require('../assets/images/main1.png')}
                src={require('../assets/videos/main_03_01.mp4')}/>
              <div className={'absolute white'} style={{top: '8vw', left: '3vw'}}>
                <div className={'font-20 poppins-semibold'}>01</div>
                <div className={'medium-title notosans-black'}>고도화된<br/>타겟팅 기술보유</div>
                <div className={'font-16 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                  콘텐츠와 광고의 매칭률을 높이는<br/>
                  다양한 타겟팅 솔루션을 바탕으로<br/>
                  광고주와 퍼블리셔의 니즈를<br/>
                  모두 충족하는 애드 네트워크 플랫폼.
                </div>
              </div>
            </div>

            <div
              className={'flex-1 relative'}
              onMouseOver={event => video2Ref?.current?.play()}
              onMouseOut={event => video2Ref?.current?.pause()}
            >
              <video
                ref={video2Ref}
                className={'vertical-video'}
                muted
                playsInline
                poster={require('../assets/images/main2.png')}
                src={require('../assets/videos/main_03_02.mp4')}/>
              <div className={'absolute white'} style={{top: '8vw', left: '3vw'}}>
                <div className={'font-20 poppins-semibold'}>02</div>
                <div className={'medium-title notosans-black'}>주요 언론사 집중<br/>광고 노출</div>
                <div className={'font-16 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                  국내 주요 언론사와 제휴하여<br/>
                  인벤토리를 확보하고,<br/>
                  80% 이상의 매체<br/>
                  상단 프리미엄 지면에<br/>
                  우선 순위로 광고를 노출합니다.
                </div>
              </div>
            </div>

            <div
              className={'flex-1 relative'}
              onMouseOver={event => video3Ref?.current?.play()}
              onMouseOut={event => video3Ref?.current?.pause()}
            >
              <video
                ref={video3Ref}
                className={'vertical-video'}
                muted
                playsInline
                poster={require('../assets/images/main3.png')}
                onMouseOver={event => event.target.play()}
                onMouseOut={event => event.target.pause()}
                src={require('../assets/videos/main_03_03.mp4')}/>
              <div className={'absolute white'} style={{top: '8vw', left: '3vw'}}>
                <div className={'font-20 poppins-semibold'}>03</div>
                <div className={'medium-title notosans-black'}>구글 공식<br/>파트너사</div>
                <div className={'font-16 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                  2021년 Google MCM 공식 파트너로<br/>
                  선정된 후 운영 노하우를 인정받아<br/>
                  2023년 GCPP로 선정,<br/>
                  광고 인벤토리 전략 수립과 운영 관리 등<br/>
                  다양한 광고 수익화 관련<br/>
                  컨설팅 서비스를 제공합니다.
                </div>
                <img style={{width: '18vw', marginTop: '6vw'}} src={require('../assets/images/mobile/google_badge.png')}/>
              </div>
            </div>

            <div
              className={'flex-1 relative'}
              onMouseOver={event => video4Ref?.current?.play()}
              onMouseOut={event => video4Ref?.current?.pause()}
            >
              <video
                ref={video4Ref}
                className={'vertical-video'}
                muted
                playsInline
                poster={require('../assets/images/main4.png')}
                onMouseOver={event => event.target.play()}
                onMouseOut={event => event.target.pause()}
                src={require('../assets/videos/main_03_04.mp4')}/>
              <div className={'absolute white'} style={{top: '8vw', left: '3vw'}}>
                <div className={'font-20 poppins-semibold'}>04</div>
                <div className={'medium-title notosans-black'}>BRAND<br/>SAFETY</div>
                <div className={'font-16 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                  브랜드 가치를 저해하는<br/>
                  요소를 사전 차단하는<br/>
                  차별화된 Viewability를 통해<br/>
                  디지털 미디어에서의<br/>
                  예상치 못한 브랜드 이미지<br/>
                  손실을 방지합니다.
                </div>
              </div>
            </div>
          </div>

          <div className={'flex-1 white-bg align-center flex-column section height-100vh'} style={{marginTop: 0}}>
            <div className={'notosans-black text-center sub-title'} style={{marginTop: '3vh'}}>브랜딩, 구매 전환, 앱 설치
              등 다양한 KPI의 광고 캠페인 집행
            </div>
            <div className={'font-22 notosans-regular margin-top-32 text-center'}>
              인터웍스미디어는 고도화된 타겟팅 솔루션과 다양한 상품을 통해<br/>
              300개 이상의 광고주와 함께하고 있습니다.
            </div>
            <div style={{marginTop: '2vh'}}>
              <img style={{width: '75vw'}} src={brandImage}/>
            </div>
          </div>

          <div className={'relative white-bg section width-100-vw'} style={{marginTop: 0, height: 'calc(100dvh - 70px)'}}>
            <img className={'container-img'} src={require('../assets/images/main4_bg.png')}/>
            <div className={'absolute width-100'} style={{top: 0, left: 0}}>
              <div className={'flex-center flex-column width-100'} style={{paddingTop: '5vh'}}>
                <div className={'notosans-black sub-title'}>전략적 파트너십을 통한 다양한 인벤토리 확보</div>
                <div className={'font-22 notosans-regular margin-top-20 text-center padding-horizontal-38'} style={{lineHeight: 1.8}}>
                  인터웍스미디어는 퍼블리셔들에게 유니크한 자사 광고 및 다양한 3rd party 업체와의 연동을 통해 인벤토리 가치를 극대화합니다.
                </div>
              </div>
              <div className={'flex-center flex-column width-100'} style={{marginTop: '5vh'}}>
                <div style={{marginTop: '1vh'}}>
                  <img className={'partnership-image'} src={partnershipImage}/>
                </div>
              </div>
            </div>
          </div>

          {/*<div className={'section'} style={{height: '50vh', width: '100%', marginTop: 0}}>*/}
          {/*  <GoogleMapReact*/}
          {/*    bootstrapURLKeys={{key: "AIzaSyBiCHb55ZrG9ymhqSS1QeR2S5Cx87tGr5k"}}*/}
          {/*    defaultCenter={defaultProps.center}*/}
          {/*    defaultZoom={defaultProps.zoom}*/}
          {/*    yesIWantToUseGoogleMapApiInternals*/}
          {/*  >*/}
          {/*    <Marker lat={37.508181} lng={127.062797}/>*/}
          {/*  </GoogleMapReact>*/}
          {/*</div>*/}

          <div className={'section'} style={{marginTop: 0}}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{right: 27, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => scrollRef?.current?.scrollTo(0, 0)}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
        :
        <div ref={scrollRef} className={'mobile-container relative'} style={{marginTop: 80}}>
          <div className={'relative section mobile-height-100vh'}>
            <img className={'mobile-container-img'} src={require('../assets/images/mobile/main1_bg.png')}/>
            {showBanners ? null :
              <div className={'absolute'} style={{top: '14vw', left: '3vw', zIndex: 9}}>
                <Fade direction={'left'}>
                  <img style={{width: '80vw'}} src={require('../assets/images/mobile/main1_text.png')}/>
                  <div className={'font-14 white margin-top-8'}>
                    인터웍스미디어는 브랜드에 최적화한 맞춤 솔루션과<br/>
                    고도화된 필터링 기술로<br/>
                    DSP와 SSP를 통합적으로 운용하는<br/>
                    프리미엄 애드 네트워크 플랫폼입니다.
                  </div>
                </Fade>
              </div>
            }

            <div className={'absolute screen width-100'} style={{top: '44vw', left: 0}}
                 onClick={() => {
                   setOpenMenu(false)
                   setTimeout(() => setOpenMenu(true), 100)
                   // navigate('/nav', {state: {isOpen: true}})
                 }}>
              <div className={'relative'} align={'center'} style={{marginLeft: -2}}>
                <video playsInline muted autoPlay width={SCREEN_WIDTH - 36} height={250}
                       style={{zIndex: 1, objectFit: 'cover'}}
                       src={require('../assets/videos/m_main_01.mp4')}/>
                <div className={'mobile-screen-btn poppins-black flex-center'}
                     style={{marginTop: 0, width: '40vw', height: 52}}>DETAIL VIEW
                </div>
                <div className={'absolute align-center'} style={{top: 0, left: 0, width: SCREEN_WIDTH, height: 250}}>
                  <img style={{width: '35vw'}} src={require('../assets/images/play_main.png')}/>
                </div>
              </div>
              <div className={'margin-top-54'} style={{marginRight: '3vw', zIndex: 9}}>
                <Fade direction={'right'}>
                  <div className={'white notosans-regular text-right font-14'} style={{lineHeight: 1.6}}>
                    정확한 타겟팅과 다양한 상품을 지원하며<br/>
                    가장 좋은 위치의 지면을 확보하여<br/>
                    효과적인 광고 캠페인 집행이 가능합니다.
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          <div className={'relative white-bg section'} style={{marginTop: -5}}>
            <img className={'width-100'} src={require('../assets/images/mobile/main2_bg.png')}/>
            <div className={'absolute width-100 align-center flex-column'} style={{top: '45vw'}}>
              <Fade direction={'left'}>
                <div className={'poppins-black gray text-center'} style={{fontSize: '12vw', lineHeight: 1.1}}>
                  Premium AD<br/> Network<br/> Platfrom
                </div>
              </Fade>
              <div className={'margin-top-54'}>
                <Fade direction={'right'}>
                  <div className={'notosans-regular text-center font-14 gray'} style={{lineHeight: 1.6}}>
                    애드 네트워크 플랫폼은<br/>
                    개별적으로 존재했던 각 사이트의 인벤토리를<br/>
                    하나의 네트워크로 통합하고<br/>
                    광고의 효율적인 퍼포먼스 및 최적화 관리가<br/>
                    가능한 플랫폼입니다.
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          <div className={'flex-1 relative section'} style={{marginTop: -5, height: 'calc(100vh + 80px)'}}>
            <img className={'width-100 container-img absolute'} style={{top: 0, left: 0, height: 'calc(100vh + 80px)'}}
                 src={require('../assets/images/mobile/main1_bg.png')}/>
            <div className={'flex-1 flex-center'} style={{marginTop: 0}}>
              <Carousel
                ref={carouselRef}
                swipeable={true}
                emulateTouch={true}
                showThumbs={false}
                showArrows={false}
                showIndicators={false}
                renderIndicator={(clickHandler, isSelected, index) => {
                  return (
                    <span key={`dot-${index}`} className={`dot`}
                          style={{backgroundColor: isSelected ? '#3E3E3E' : '#B5B5B5', borderWidth: 0}}/>
                  )
                }}
                showStatus={false}
                autoPlay={false}
                infiniteLoop={false}
                centerMode={true}
                centerSlidePercentage={88}
                width={'100vw'}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                animationHandler={'slide'}
                interval={3000}
              >
                <div className={'relative mobile-video-wrapper'}>
                  <video
                    className={'mobile-vertical-video'}
                    muted
                    playsInline
                    poster={require('../assets/images/mobile/main1.png')}
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    src={require('../assets/videos/m_main_01_03.mp4')}/>
                  <div className={'absolute white'} style={{top: '12vw', left: '5vw'}}>
                    <div className={'font-20 poppins-semibold'}>01</div>
                    <div className={'font-40 notosans-black'}>고도화된<br/>타겟팅 기술보유</div>
                    <div className={'font-14 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                      콘텐츠와 광고의 매칭률을 높이는<br/>
                      다양한 타겟팅 솔루션을 바탕으로<br/>
                      광고주와 퍼블리셔의 니즈를<br/>
                      모두 충족하는 애드 네트워크 플랫폼.
                    </div>
                  </div>
                </div>
                <div className={'relative mobile-video-wrapper'}>
                  <video
                    className={'mobile-vertical-video'}
                    muted
                    playsInline
                    poster={require('../assets/images/mobile/main2.png')}
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    src={require('../assets/videos/m_main_01_03_02.mp4')}/>
                  <div className={'absolute white'} style={{top: '12vw', left: '5vw'}}>
                    <div className={'font-20 poppins-semibold'}>02</div>
                    <div className={'font-40 notosans-black'}>주요 언론사 집중<br/>광고 노출</div>
                    <div className={'font-14 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                      국내 주요 언론사와 제휴하여<br/>
                      인벤토리를 확보하고,<br/>
                      80% 이상의 매체<br/>
                      상단 프리미엄 지면에<br/>
                      우선 순위로 광고를 노출합니다.
                    </div>
                  </div>
                </div>

                <div className={'relative mobile-video-wrapper'}>
                  <video
                    className={'mobile-vertical-video'}
                    muted
                    playsInline
                    poster={require('../assets/images/mobile/main3.png')}
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    src={require('../assets/videos/m_main_01_03_03.mp4')}/>
                  <div className={'absolute white'} style={{top: '12vw', left: '5vw'}}>
                    <div className={'font-20 poppins-semibold'}>03</div>
                    <div className={'font-40 notosans-black'}>구글 공식<br/>파트너사</div>
                    <div className={'font-14 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                      2021년 Google MCM 공식 파트너로<br/>
                      선정된 후 운영 노하우를 인정받아<br/>
                      2023년 GCPP로 선정,<br/>
                      광고 인벤토리 전략 수립과 운영 관리 등<br/>
                      다양한 광고 수익화 관련<br/>
                      컨설팅 서비스를 제공합니다.<br/>
                    </div>
                    <img style={{width: '70vw', marginTop: 20}} src={require('../assets/images/mobile/google_badge.png')}/>
                  </div>
                </div>

                <div className={'relative mobile-video-wrapper'}>
                  <video
                      className={'mobile-vertical-video'}
                      muted
                      playsInline
                      poster={require('../assets/images/mobile/main4.png')}
                      onMouseOver={event => event.target.play()}
                      onMouseOut={event => event.target.pause()}
                      src={require('../assets/videos/m_main_01_03_04.mp4')}/>
                  <div className={'absolute white'} style={{top: '12vw', left: '5vw'}}>
                    <div className={'font-20 poppins-semibold'}>04</div>
                    <div className={'font-40 notosans-black'}>BRAND<br/>SAFETY</div>
                    <div className={'font-14 notosans-regular margin-top-20'} style={{lineHeight: 1.6}}>
                      브랜드 가치를 저해하는<br/>
                      요소를 사전 차단하는<br/>
                      차별화된 Viewability를 통해<br/>
                      디지털 미디어에서의<br/>
                      예상치 못한 브랜드 이미지<br/>
                      손실을 방지합니다.
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>

          <div className={'flex-1 white-bg flex-column section'} style={{marginTop: 0}}>
            <div className={'notosans-black text-center'} style={{fontSize: 28, marginTop: '20vw'}}>
              브랜딩, 구매 전환, 앱 설치 등<br/>
              다양한 KPI의 광고 캠페인 집행
            </div>
            <div className={'font-14 notosans-regular margin-top-6 text-center'}>
              인터웍스미디어는<br/>
              고도화된 타겟팅 솔루션과 다양한 상품을 통해<br/>
              300개 이상의 광고주와 함께하고 있습니다.
            </div>
            <div className={'horizontal'} style={{marginTop: '6vw', marginBottom: '10vw', maxWidth: '100vw', overflowX: 'scroll', overflowY: 'hidden'}}>
              <img style={{width: '240vw'}} src={brandImage}/>
            </div>
          </div>

          <div className={'relative white-bg section mobile-container-img'} style={{marginTop: 0}}>
            <img className={'mobile-container-img'} src={require('../assets/images/mobile/main4_bg.png')}/>
            <div className={'absolute width-100'} style={{top: 0, left: 0}}>
              <div className={'flex-center flex-column width-100'} style={{marginTop: '20vw'}}>
                <div className={'notosans-black text-center'} style={{fontSize: 28}}>전략적 파트너십을 통한<br/>다양한 인벤토리 확보</div>
                <div className={'font-14 margin-top-10 text-center'} style={{lineHeight: 1.8}}>
                  인터웍스미디어는 퍼블리셔들에게<br/>유니크한 자사 광고 및 다양한 3rd party 업체와의<br/> 연동을 통해 인벤토리 가치를 극대화합니다.
                </div>
              </div>
              <div className={'partners-wrapper'}>
                <div className={'flex-row'}>
                  {partners?.map((item, index) => {
                    const isActive = item === partner
                    return (
                      <div
                        className={`align-center ${isActive ? 'active-partner-btn' : 'partner-btn'}`}
                        onClick={() => setPartner(item)}
                      >
                        <div>{item}</div>
                      </div>
                    )
                  })}
                </div>
                <div className={'white-bg'} style={{minHeight: '75vw'}}>
                  <img style={{width: '84vw'}}
                       src={partner === partners[0] ? mobilePartnershipImage1 : mobilePartnershipImage2}/>
                </div>
              </div>
            </div>
          </div>
          {/*<div className={'section'} style={{height: '50vh', width: '100%', marginTop: -8}}>*/}
          {/*  <GoogleMapReact*/}
          {/*    bootstrapURLKeys={{key: "AIzaSyBiCHb55ZrG9ymhqSS1QeR2S5Cx87tGr5k"}}*/}
          {/*    defaultCenter={defaultProps.center}*/}
          {/*    defaultZoom={defaultProps.zoom}*/}
          {/*    yesIWantToUseGoogleMapApiInternals*/}
          {/*  >*/}
          {/*    <Marker lat={37.508181} lng={127.062797}/>*/}
          {/*  </GoogleMapReact>*/}
          {/*</div>*/}
          <div className={'section'}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{left: 16, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => {window?.scrollTo(0, 0)}}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
      }
      <Modal
        isOpen={showBanners}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setShowBanners(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className={'relative'}>
          <div className={''} style={{overflowY: 'scroll', overflowX: 'hidden', padding: 0, maxHeight: isPc ? 540 : 360}}>
            <Carousel
              className={'custom-carousel'}
              swipeable={true}
              emulateTouch={true}
              showThumbs={false}
              showArrows={true}
              showIndicators={false}
              showStatus={false}
              autoPlay={false}
              infiniteLoop={false}
              centerMode={false}
              centerSlidePercentage={12}
              width={isPc ? 540 : 360}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={20}
              animationHandler={'slide'}
              interval={3000}
              // onChange={index => setIndex(index)}
              renderArrowPrev={(clickHandler, hasPrev, label) => {
                  return (
                    <div onClick={clickHandler} className={'fixed'} style={{left: 0, top: isPc ? 220 : 130, zIndex: 99}}>
                      <img style={{height: 80, width: 30, backgroundColor: '#C52922'}}
                           src={require('../assets/images/new_left.png')}/>
                    </div>
                  )
                }}
                renderArrowNext={(clickHandler, hasPrev, label) => {
                  return (
                    <div onClick={clickHandler} className={'fixed'} style={{right: 0, top: isPc ? 220 : 130, zIndex: 99}}>
                      <img style={{height: 80, width: 30, backgroundColor: '#000'}}
                           src={require('../assets/images/new_right.png')}/>
                    </div>
                  )
                }}
            >
              {banners?.map((item, index) => {
                return (
                  <div className={'margin-bottom-40'} style={{overflowY: 'scroll', overflowX: 'hidden'}}>
                    <img style={{width: isPc ? 480 : 300}} src={item?.image}/>
                  </div>
                )
              })}
            </Carousel>
          </div>
          <div className={'flex-row fixed width-100 black-bg'} style={{left: 0, bottom: 0, zIndex: 999}}>
            <div className={'flex-row width-100 padding-10'}>
              <div className={'font-16 flex-1 blur-white'} onClick={() => {
                notShowBanner()
                setShowBanners(false)
              }}>5일간 보지 않기</div>
              <div className={'font-16 flex-end blur-white'} onClick={() => setShowBanners(false)}>닫기</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
