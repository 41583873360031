import './App.css';
import {Route, Routes} from "react-router-dom";
import {Main} from "./pages/Main";
import {Header} from "./components/Header";
import {ADProduct} from "./pages/ADProduct";
import {Publisher} from "./pages/Publisher";
import {Nav} from "./pages/Nav";
import {Footer} from "./components/Footer";
import {Contact} from "./pages/Contact";
import {Career} from "./pages/Career";

function App() {
  return (
    <div
      className={`ro3ot`}
      style={{
        display: 'flex',
        padding: "0 0 0 0",
        width: '100%',
        // minWidth: window.innerWidth,
        overflowX: "hidden",
        minHeight: "100%",
        backgroundColor: '#181818',
        paddingLeft: 0,
        paddingRight: 0
      }}
    >
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/ad-product" element={<ADProduct />} />
        <Route path="/publisher" element={<Publisher />} />
        <Route path="/nav" element={<Nav />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
