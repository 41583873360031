import '../static/css/header.css'
import {useLocation, useNavigate} from "react-router-dom";
import {isIOS, isPc, sendRequest} from "../static/js/common";
import {useEffect, useRef, useState} from "react";
import { slide as Menu } from 'react-burger-menu'



export const Header = ({scrollRef, openMenu=false}) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [brochureUrl, setBrochureUrl] = useState("");
  const [hover, setHover] = useState(false);
  const [adUrl, setAdUrl] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   setIsOpen(!!location.state?.isOpen)
  // }, [location]);

  useEffect(() => {
    setIsOpen(!!openMenu)
  }, [openMenu]);

  const getBrochure = () => {
    sendRequest('config/config/?key=BROCHURE', 'GET', null, data => {
      setBrochureUrl(data?.[0]?.file)
    })
  }

  const getUrls = () => {
    sendRequest('config/config/?key=광고주URL', 'GET', null, data => {
      setAdUrl(data?.[0]?.value)
    })
    sendRequest('config/config/?key=매체사URL', 'GET', null, data => {
      setMediaUrl(data?.[0]?.value)
    })
  }

  useEffect(() => {
    getBrochure()
    getUrls()
  }, [])

  return (
    <div>
      <div className={'header flex-1 flex-row'}>
        <div className={'flex-1'}>
          <div className={'white font-16 poppins-black cursor'} onClick={() => {
            navigate('/')
            scrollRef?.current?.scrollTo(0, 0)
          }}>INTERWORKS</div>
        </div>
        <div className={'flex-3 flex-center flex-row horizontal-end pc-container'} style={{marginTop: 0}}>
          <div className={'white font-16 notosans-black cursor header-menu'}
               onClick={() => navigate('/ad-product')}>AD Product
          </div>
          <div className={'white font-16 notosans-black cursor margin-left header-menu'}
               onClick={() => navigate('/publisher')}>Publisher
          </div>
          <div className={'white font-16 notosans-black cursor margin-left header-menu'}
               onClick={() => navigate('/career')}>Careers
          </div>
          <div className={'white font-16 notosans-black cursor margin-left header-menu'}
               onClick={() => navigate('/contact')}>Contact us
          </div>
          <div
            className={'brochure cursor notosans-regular font-14 margin-left'}
            onClick={() => window.open(brochureUrl)}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{backgroundColor: hover ? '#fff' : '#868686', color: hover ? '#333' : '#fff'}}
          >COMPANY BROCHURE</div>
          <div className={'select-box cursor notosans-regular flex-row font-14'} style={{marginLeft: '0.5vw'}}>
            <select
              className={'select font-14 cursor'}
              defaultValue={'리포트'}
              onChange={(e) => window.open(e.target.value)}
            >
              <option disabled={true}>
                리포트
                <img className={'icon-12 margin-left-8'} src={require('../assets/images/arrow_down.png')}/>
              </option>
              <option value={adUrl}>광고주</option>
              <option value={mediaUrl}>매체사</option>
            </select>
          </div>
        </div>
        <div className={'flex-end mobile-container'} onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <img className={'icon-36'} src={require('../assets/images/mobile/header_menu.png')}/>
        </div>
      </div>
      {isOpen && <Menu
          isOpen={isOpen}
          right
          className={'width-100-vw'}

        >
          <div className={'relative black-bg'} style={{paddingTop: 100, overflow: 'visible', paddingBottom: 200}}>
            {!loading &&
              <div className={'relative'}>
                {isIOS() ?
                  <div className={'relative flex-center cursor'} onClick={() => navigate('/ad-product')}>
                    <img style={{width: '90vw', marginLeft: '0vw'}}
                         src={require('../assets/images/mobile/nav_adproduct.png')}/>
                  </div>
                  :
                  <div className={'relative flex-center cursor'} onClick={() => navigate('/ad-product')}>
                    <img style={{width: '90vw', marginLeft: '0vw'}}
                         src={require('../assets/images/mobile/nav_adproduct.png')}/>
                  </div>
                }
                <div className={'relative flex-center cursor'} onClick={() => navigate('/publisher')}>
                  <img style={{width: '90vw', marginLeft: '0vw', marginTop: '2vw'}}
                       src={require('../assets/images/mobile/nav_publisher.png')}/>
                </div>
                <div className={'flex-row'} style={{marginLeft: '5vw', marginTop: '2vw'}}>
                  <div className={'relative cursor'} onClick={() => navigate('/career')}>
                    <img style={{width: '44vw'}} src={require('../assets/images/mobile/nav_career.png')}/>
                  </div>
                  <div className={'relative cursor'} style={{marginLeft: '2vw'}}
                       onClick={() => window.open(brochureUrl)}>
                    <img style={{width: '44vw'}} src={require('../assets/images/mobile/broschure.png')}/>
                  </div>
                </div>
                <div className={'margin-top-32 flex-row'} style={{paddingLeft: '5vw', paddingRight: '5vw'}}>
                  <div className={'poppins-black white font-24 margin-top-12 flex-1 flex-row'}
                       onClick={() => navigate('/contact')}>Contact
                    Us &nbsp;<span className={'notosans-bold font-10'}>문의하기</span>
                  </div>
                  <div className={'flex-end'}>
                    <div className={'select-box cursor notosans-regular flex-row font-14'}
                         style={{marginLeft: '0.5vw'}}>
                      <select
                        className={'select font-14 cursor'}
                        defaultValue={'리포트'}
                        onChange={(e) => window.open(e.target.value)}
                      >
                        <option disabled={true}>
                          리포트
                          <img className={'icon-12 margin-left-8'} src={require('../assets/images/arrow_down.png')}/>
                        </option>
                        <option value={adUrl}>광고주</option>
                        <option value={mediaUrl}>매체사</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Menu>
      }
    </div>
  )
}
