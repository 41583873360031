
export var API_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'https://app.interworksmedia.co.kr/api/v1/' : 'https://app.interworksmedia.co.kr/api/v1/'

export const isPc = window.innerWidth > 450

export const SCREEN_WIDTH = window.innerWidth

export const SCREEN_HEIGHT = window.innerHeight

export var SUCCESS = 1;
export var FAIL = -1;


export function isIOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}



export const storeData = async (key, data) => {
  try {
    await localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    alert(error);
  }
};

export const retrieveData = async key => {
  try {
    return await JSON.parse(localStorage.getItem(key));
  } catch (error) {}
};

export const removeData = async key => {
  try {
    await localStorage.removeItem(key);
  } catch (error) {}
};



export function sendRequest(url, method, body, onSuccess, onError, token) {
  fetch(API_URL + url, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body:
      ['POST', 'PUT', 'PATCH'].indexOf(method) !== -1
        ? JSON.stringify(body)
        : null,
  })
    .then(response => response.json())
    .then(responseJson => {
      // alert(responseJson.linusCode)
      // console.log(JSON.stringify(responseJson))
      if (responseJson.linusCode === SUCCESS) {
        if (onSuccess) {
          onSuccess(responseJson.data, responseJson.page);
        }
      } else {
        // if (responseJson.linusCode === AUTH_FAIL) {
          // removeData('accessToken')
          // sendRequest(url, method, body, onSuccess, onError, null)
        // } else
        if (onError) {
          onError(responseJson.data, responseJson.linusCode);
        } else {
          console.log(
            (responseJson.data ? responseJson.data.msg : '서버 에러') +
            ' [' +
            responseJson.linusCode +
            '] ' +
            url,
          );
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
}

export function sendFormRequest(url, method, body, onSuccess, onError) {
  if (['POST', 'PUT'].indexOf(method) === -1) {
    alert('Error on method!');
    return;
  }
  fetch(API_URL + url, {
    method: method,
    headers: {
      // Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
    },
    body: body,
  })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.linusCode === SUCCESS) {
        if (onSuccess) {
          onSuccess(responseJson.data);
        }
      } else {
        if (onError) {
          onError(
            responseJson.data ? responseJson.data : responseJson,
            responseJson.linusCode,
          );
        } else {
          console.log(
            (responseJson.data ? responseJson.data.msg : '서버 에러') +
            ' [' +
            responseJson.linusCode +
            '] ' +
            url,
          );
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
}


export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function validatePhone(phone) {
  return /^\d+$/.test(phone);
}

export function fillZero(value){
    if(value >= 10){
        return value.toString()
    } else {
        return '0' + value
    }
}

export function getDateStr (date) {
    return date?.getFullYear() + '-' + fillZero(date?.getMonth() + 1) + '-' + fillZero(date?.getDate())
}

export const getTime = (date) => {
    return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
}
export const getDateTime = (date) => {
    return getDateStr(date) + ' ' + getTime(date)
}
