import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {useEffect, useRef, useState} from "react";
import {Button} from "../modules/Button";
import {TextInput} from "../modules/TextInput";
import {isPc, SCREEN_WIDTH, sendFormRequest, sendRequest} from "../static/js/common";


export const Career = ({}) => {

  const scrollRef = useRef(null)

  const tags = [
    '#Proactive',
    '#Collaborative',
    '#Progressive',
  ]

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [field, setField] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [agreePrivacy, setAgreePrivacy] = useState(false)
  const [agreeSensitive, setAgreeSensitive] = useState(false)
  const [file, setFile] = useState("");
  const fileRef = useRef()
  const [agreePrivacyOnCareerTitle, setAgreePrivacyOnCareerTitle] = useState('')
  const [agreePrivacyOnCareer, setAgreePrivacyOnCareer] = useState('')
  const [agreeSensitiveOnCareerTitle, setAgreeSensitiveOnCareerTitle] = useState('')
  const [agreeSensitiveOnCareer, setAgreeSensitiveOnCareer] = useState('')

  const saveCareer = () => {
    if (!name) {
      alert('이름을 입력해 주세요.')
      return
    }

    if (!email) {
      alert('이메일을 입력해 주세요.')
      return
    }

    if (!agreePrivacy) {
      alert('개인정보보호정책에 동의해 주세요.')
      return
    }

    if(!file?.size) {
      alert('파일을 업로드 해주세요.')
      return
    }

    sendRequest('career/career/', 'POST', {
      name,
      phone,
      email,
      field,
      title,
      description,
      agreePrivacy,
    }, data => {
      if (file?.size) {
        const formData = new FormData();
        formData.append('file', file)
        sendFormRequest(`career/career-file/${data?.id}/`, 'POST', formData)
      }
    })

    alert('채용 지원이 올바르게 신청 되었습니다.')
  }

  const getConfig = () => {
    sendRequest('config/config/?key=agreePrivacyOnCareer', 'GET', null, data => {
      const agreement = data[0]
      setAgreePrivacyOnCareerTitle(agreement?.value)
      setAgreePrivacyOnCareer(agreement?.desc)
    })

    sendRequest('config/config/?key=agreeSensitiveOnCareer', 'GET', null, data => {
      const agreement = data[0]
      setAgreeSensitiveOnCareerTitle(agreement?.value)
      setAgreeSensitiveOnCareer(agreement?.desc)
    })
  }

  useEffect(() => {
    getConfig()
    window.scrollTo(0,0);
  }, [])

  return (
    <div>
      <Header/>
      {isPc ?
        <div ref={scrollRef} className={'pc-container scroll-snap'} style={{marginTop: 80}}>
          <div className={'relative section'}>
            <video playsInline muted autoPlay className={'width-100'}
                   style={{
                     minWidth: window.innerWidth,
                     minHeight: window.innerHeight - 80,
                     maxHeight: window.innerHeight - 80,
                     objectFit: 'cover'
                   }}
                   src={require('../assets/videos/sub_03_01.mp4')}/>
            <div className={'absolute flex-center flex-column'} style={{top: '38vh', left: 0, width: '100%'}}>
              <div className={'poppins-black darkgray main-title'} style={{lineHeight: 1.1}}>CAREERS</div>
              {/*<div style={{marginTop: '18vw'}}>*/}
              {/*  <img style={{width: 80}} src={require('../assets/images/play_down.png')}/>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className={'relative section'} style={{marginTop: -5}}>
            <img className={'width-100 container-img'} src={require('../assets/images/publisher1_bg.png')}/>
            <div className={'absolute width-100 flex-center flex-column'} style={{top: '9vh'}}>
              <div className={'bold notosans-black text-center small-title'}>
                사람과 데이터를 가장 유기적으로 결합하여<br/>
                connectivity 가치를 드높이는 네트워크 기업
              </div>
              {/*<div className={'flex-row margin-top-54'}>*/}
              {/*  {tags?.map((item, index) => {*/}
              {/*    return (*/}
              {/*      <div*/}
              {/*        className={`adproduct-menu notosans-bold`}*/}
              {/*        style={{borderWidth: 2}}*/}
              {/*      >*/}
              {/*        {item}*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*  })}*/}
              {/*</div>*/}
              <div style={{marginTop: '9vh'}}>
                <img style={{height: '14vh'}} src={require('../assets/images/career/text1.png')}/>
              </div>
              <div style={{marginTop: '2vh'}}>
                <img style={{height: '14vh'}} src={require('../assets/images/career/text2.png')}/>
              </div>
              <div style={{marginTop: '2vh'}}>
                <img style={{height: '14vh'}} src={require('../assets/images/career/text3.png')}/>
              </div>
              {/*<div className={'text-center notosans-bold'} style={{marginTop: '4vh', fontSize: 40}}>*/}
              {/*  진취적인 조직 문화를 조성합니다.*/}
              {/*</div>*/}
            </div>
          </div>
          <div className={'white-bg relative section'} style={{marginTop: -5}}>
            <img className={'width-100 container-img'} style={{minWidth: window.innerWidth, objectFit: 'cover'}}
                 src={require('../assets/images/career3_bg.png')}/>
            <div className={'absolute width-100 flex-center height-100vh'} style={{top: 0, left: 0}}>
              <div className={'career-step-img-wrapper'} style={{width: '65vw'}}>
                <img className={'width-100 career-step-img'} src={require('../assets/images/careers.png')}/>
              </div>
            </div>
          </div>

          <div className={'relative flex-center white-bg section'} style={{marginTop: -5, scrollSnapAlign: 'start'}}>
            <img className={'width-100'} height={1420} src={require('../assets/images/career4_bg.png')}/>
            <div className={'absolute flex-column width-100 align-center'} style={{top: '3vw'}}>
              <div className={'career-box flex-column'}>
                <div className={'notosans-black text-center sub-title'}>지원서 작성</div>
                <div className={'margin-top-32'}>
                  <div className={'flex-row'}>
                    <div className={'flex-1'}>
                      <TextInput placeholder={'이름'} backgroundColor={'transparent'} fontSize={20}
                                 placeholderColor={'dark-placeholder'} value={name} setValue={setName}/>
                    </div>
                    <div className={'flex-1'} style={{marginLeft: '3.5vw'}}>
                      <TextInput placeholder={'연락처'} backgroundColor={'transparent'} fontSize={20}
                                 placeholderColor={'dark-placeholder'} value={phone} setValue={setPhone}/>
                    </div>
                  </div>
                </div>
                <div className={'margin-top-32'}>
                  <div className={'flex-row'}>
                    <div className={'flex-1'}>
                      <TextInput placeholder={'이메일'} backgroundColor={'transparent'} fontSize={20}
                                 placeholderColor={'dark-placeholder'} value={email} setValue={setEmail}/>
                    </div>
                    <div className={'flex-1'} style={{marginLeft: '3.5vw'}}>
                      <TextInput placeholder={'지원분야'} backgroundColor={'transparent'} fontSize={20}
                                 placeholderColor={'dark-placeholder'} value={field} setValue={setField}/>
                    </div>
                  </div>
                </div>
                <div className={'margin-top-32'}>
                  <TextInput
                    placeholder={'제목'}
                    backgroundColor={'#fff'}
                    height={55}
                    padding={'1px 0 0 20px'}
                    fontSize={20}
                    placeholderColor={'dark-placeholder'}
                    value={title}
                    setValue={setTitle}
                  />
                </div>
                <div className={'margin-top-20'}>
                  <TextInput
                    placeholder={'내용'}
                    backgroundColor={'#fff'}
                    height={219}
                    padding={'20px 0 0 20px'}
                    multiline={true}
                    fontSize={20}
                    placeholderColor={'dark-placeholder'}
                    value={description}
                    setValue={setDescription}
                  />
                </div>

                <div className={'flex-center margin-top-32'} align={'center'}>
                  <div className={'flex-row white-bg'} style={{padding: '16px 32px', borderRadius: 32}}
                       onClick={() => fileRef?.current?.click()}>
                    <input ref={fileRef} type={'file'} accept={'application/pdf'} style={{display: 'none'}}
                           onChange={e => {
                             const file_ = e?.target.files[0]
                             if (file_?.size > 50 * 1024 * 1024) {
                               alert('파일 용량은 50MB 이하로 업로드 해주세요.')
                               return
                             }
                             setFile(file_)
                           }}/>
                    <div className={'file-box flex-center'} style={{maxWidth: 69}}>
                      <img className={'icon-24'} src={require('../assets/images/attachment.png')}/>
                    </div>
                    <div className={'flex-column text-left margin-left-20'}>
                      <div
                        className={'gray font-20'}>{file?.size ? file?.name : '이력서와 포트폴리오는 PDF 파일로 업로드 부탁드립니다.'}
                      </div>
                      <div className={'font-10 gray9'}>
                        *이력서 내 주민등록번호 뒷자리는 필수 삭제 후 최소한의 개인정보 업로드
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'margin-top-54 flex-row-wo-center'}>
                  <div className={'flex-1 flex-column'}>
                    <div className={'flex-row'} onClick={() => setAgreePrivacy(!agreePrivacy)}>
                      <img className={'icon-22'}
                           src={agreePrivacy ? require('../assets/images/black_check_on.png') : require('../assets/images/check.png')}/>
                      <div className={`${agreePrivacy ? 'black' : 'gray9'} bold margin-left-16`}>
                        <span className={'underline'} style={{fontSize: '2vh'}}>{agreePrivacyOnCareerTitle}</span>
                      </div>
                    </div>
                    <div className={'gray9 agreement pre-wrap'}>
                      {agreePrivacyOnCareer}
                    </div>
                  </div>

                  <div className={'flex-1 flex-column margin-left-20'}>
                    <div className={'flex-row'} onClick={() => setAgreeSensitive(!agreeSensitive)}>
                      <img className={'icon-22'}
                           src={agreeSensitive ? require('../assets/images/black_check_on.png') : require('../assets/images/check.png')}/>
                      <div className={`${agreeSensitive ? 'black' : 'gray9'} bold margin-left-16`}>
                        <span className={'underline'} style={{fontSize: '2vh'}}>{agreeSensitiveOnCareerTitle}</span>
                      </div>
                    </div>
                    <div className={'gray9 agreement pre-wrap'}>
                      {agreeSensitiveOnCareer}
                    </div>
                  </div>
                </div>

                <div className={'flex-center'} style={{marginTop: 39}}>
                  <Button text={'지원서 제출'} width={240} height={69} borderRadius={69} onClick={saveCareer}
                          onReverseHover/>
                </div>
              </div>

              <div className={'width-100 flex-start flex-column'} style={{paddingTop: 54}}>
                <div className={'font-16 notosans-regular text-center'} style={{lineHeight: 1.8}}>
                  * 전형 절차는 직무별로 다르게 운영될 수 있으며, 일정 및 상황에 따라 변동될 수 있습니다.<br/>
                  * 채용 전형 절차 종료 후 지원자의 동의하에 평판조회가 진행될 수 있습니다.
                </div>
                <div className={'flex-row margin-top-40 align-center'}>
                  <div className={'notosans-bold font-16'}>
                    채용 관련 문의&nbsp;&nbsp;&nbsp;
                  </div>
                  <div className={'font-16 notosans-black'} style={{marginTop: -5}}>recruit@iwmedia.co.kr</div>
                </div>
              </div>
            </div>
          </div>
          <div className={'section'}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{right: 27, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => scrollRef?.current?.scrollTo(0, 0)}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
        :
        <div ref={scrollRef} className={'mobile-container mobile-wrapper'} style={{marginTop: 80}}>
          <div className={'relative section mobile-height-100vh'}>
            <video playsInline muted autoPlay className={'width-100 mobile-height-100vh'}
                   style={{minWidth: window.innerWidth, objectFit: 'cover'}}
                   src={require('../assets/videos/m_sub_03_01.mp4')}/>
            <div className={'absolute width-100 height-100 flex-center flex-column'}
                 style={{top: -80, left: 0, width: '100%'}}>
              <div className={'poppins-black darkgray'} style={{fontSize: '13vw', lineHeight: 1.1}}>CAREERS</div>
            </div>
          </div>

          <div className={'relative section'} style={{marginTop: -42}}>
            <img className={'width-100 container-img'} src={require('../assets/images/mobile/career2_bg.png')}/>
            <div className={'absolute width-100 flex-center flex-column'} style={{top: '12vh'}}>
              <div className={'bold notosans-black text-center'}
                   style={{fontSize: '3vh', lineHeight: 1.5, marginTop: '2vh'}}>
                사람과 데이터를 가장 유기적으로<br/>
                결합하여 connectivity 가치를<br/>
                드높이는 네트워크 기업
              </div>
              <div style={{marginTop: '10vh'}}>
                <img style={{height: '17vw'}} src={require('../assets/images/career/text1.png')}/>
              </div>
              <div style={{marginTop: '5vh'}}>
                <img style={{height: '17vw'}} src={require('../assets/images/career/text2.png')}/>
              </div>
              <div style={{marginTop: '5vh'}}>
                <img style={{height: '17vw'}} src={require('../assets/images/career/text3.png')}/>
              </div>
              {/*<div className={'text-center notosans-bold'} style={{marginTop: '10vw', fontSize: 20}}>*/}
              {/*  진취적인 조직 문화를 조성합니다.*/}
              {/*</div>*/}
            </div>
          </div>

          <div className={'relative section width-100 mobile-height-100vh'} style={{marginTop: -5}}>
            <img className={'absolute width-100 mobile-height-100vh'} style={{top: 0, left: 0, objectFit: 'cover'}}
                 src={require('../assets/images/mobile/career3_bg.png')}/>
            <div className={'absolute notosans-black font-30'} style={{zIndex: 9, top: '14vh', left: 16}}>
              <div>영입절차</div>
              <img className={'width-100'} style={{zIndex: 9, marginTop: 50, left: 16}}
                   src={require('../assets/images/mobile/career3.png')}/>
            </div>

          </div>

          <div className={'relative align-center white-bg apply-section'}
               style={{marginTop: 0, minHeight: '100vh', height: 'auto'}}>
            <img className={'width-100'} style={{height: 'auto'}} src={require('../assets/images/mobile/career4_bg.png')}/>
            <div className={'absolute flex-column'} style={{top: '6vh'}}>
              <div className={'mobile-career-box flex-column'}>
                <div className={'notosans-black text-left font-30'}>지원서 작성</div>
                <div className={'margin-top-32'}>
                  <div className={'flex-column'}>
                    <div className={'flex-1'}>
                      <TextInput placeholder={'이름'} backgroundColor={'transparent'} fontSize={16}
                                 placeholderColor={'dark-placeholder'}
                                 value={name} setValue={setName}
                                 width={SCREEN_WIDTH - 40}
                      />
                    </div>
                    <div className={'flex-1 margin-top-32'}>
                      <TextInput placeholder={'연락처'} backgroundColor={'transparent'} fontSize={16}
                                 placeholderColor={'dark-placeholder'}
                                 value={phone} setValue={setPhone}
                                 width={SCREEN_WIDTH - 40}
                      />
                    </div>
                  </div>
                </div>
                <div className={'margin-top-32'}>
                  <div className={'flex-column'}>
                    <div className={'flex-1'}>
                      <TextInput placeholder={'이메일'} backgroundColor={'transparent'} fontSize={16}
                                 placeholderColor={'dark-placeholder'}
                                 value={email} setValue={setEmail}
                                 width={SCREEN_WIDTH - 40}
                      />
                    </div>
                    <div className={'flex-1 margin-top-32'}>
                      <TextInput placeholder={'지원분야'} backgroundColor={'transparent'} fontSize={16}
                                 placeholderColor={'dark-placeholder'}
                                 value={field} setValue={setField}
                                 width={SCREEN_WIDTH - 40}
                      />
                    </div>
                  </div>
                </div>
                <div className={'margin-top-32'}>
                  <TextInput
                    placeholder={'제목'}
                    backgroundColor={'#fff'}
                    height={55}
                    padding={'1px 0 0 20px'}
                    fontSize={16}
                    placeholderColor={'dark-placeholder'}
                    width={SCREEN_WIDTH - 40}
                    border={'none'}
                    value={title}
                    setValue={setTitle}
                  />
                </div>
                <div className={'margin-top-20'}>
                  <TextInput
                    placeholder={'내용'}
                    backgroundColor={'#fff'}
                    height={219}
                    padding={'20px 0 0 20px'}
                    multiline={true}
                    fontSize={16}
                    placeholderColor={'dark-placeholder'}
                    width={SCREEN_WIDTH - 40}
                    value={description}
                    setValue={setDescription}
                  />
                </div>

                <div className={'flex-center margin-top-54'} align={'center'}>
                  <div className={'flex-row white-bg'} style={{padding: '16px 32px', borderRadius: 32}}
                       onClick={() => fileRef?.current?.click()}>
                    <input ref={fileRef} type={'file'} accept={'application/pdf'} style={{display: 'none'}}
                           onChange={e => {
                             const file_ = e?.target.files[0]
                             if (file_?.size > 50 * 1024 * 1024) {
                               alert('파일 용량은 50MB 이하로 업로드 해주세요.')
                               return
                             }
                             setFile(file_)
                           }}/>
                    <div className={'file-box flex-center'} style={{width: 69}}>
                      <img className={'icon-24'} src={require('../assets/images/attachment.png')}/>
                    </div>
                    <div className={'gray font-14 margin-left-20'}>이력서와 포트폴리오는 PDF 파일로<br/> 업로드 부탁드립니다.</div>
                  </div>
                </div>

                <div className={'margin-top-54 flex-column'}>
                  <div className={'flex-1 flex-column'}>
                    <div className={'flex-row'} onClick={() => setAgreePrivacy(!agreePrivacy)}>
                      <img className={'icon-22'}
                           src={agreePrivacy ? require('../assets/images/black_check_on.png') : require('../assets/images/check.png')}/>
                      <div className={`${agreePrivacy ? 'black' : 'gray9'} bold font-20 margin-left-16`}>
                        <span className={'underline'}>{agreePrivacyOnCareerTitle}</span>
                      </div>
                    </div>
                    <div className={'gray9 agreement pre-wrap'}>
                      {agreePrivacyOnCareer}
                    </div>
                  </div>
                  <div className={'flex-1 flex-column margin-top-54'}>
                    <div className={'flex-row'} onClick={() => setAgreeSensitive(!agreeSensitive)}>
                      <img className={'icon-22'}
                           src={agreeSensitive ? require('../assets/images/black_check_on.png') : require('../assets/images/check.png')}/>
                      <div className={`${agreeSensitive ? 'black' : 'gray9'} bold font-20 margin-left-16`}>
                        <span className={'underline'}>{agreeSensitiveOnCareerTitle}</span>
                      </div>
                    </div>
                    <div className={'gray9 agreement pre-wrap'}>
                      {agreeSensitiveOnCareer}
                    </div>
                  </div>
                </div>

                <div className={'flex-center'} style={{marginTop: 50}}>
                  <Button text={'지원서 제출'} width={SCREEN_WIDTH - 40} height={69} borderRadius={69} onClick={saveCareer}/>
                </div>
              </div>

              <div className={'width-100 flex-start flex-column'} style={{paddingTop: 30, padding: 20}}>
                <div className={'font-14 notosans-regular text-left flex-1'} style={{lineHeight: 1.8}}>
                  * 전형 절차는 직무별로 다르게 운영될 수 있으며,<br/> 일정 및 상황에 따라 변동될 수 있습니다.<br/>
                  * 채용 전형 절차 종료 후 지원자의 동의하에<br/> 평판조회가 진행될 수 있습니다.
                </div>
                <div className={'flex-column margin-top-54'}>
                  <div className={'notosans-bold font-14'}>
                    채용 관련 문의&nbsp;&nbsp;&nbsp;
                  </div>
                  <div className={'notosans-black font-14'}>recruit@iwmedia.co.kr</div>
                </div>
              </div>
            </div>
          </div>
          <div className={'section'}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{left: 16, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => window?.scrollTo(0, 0)}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
      }

    </div>
  )
}
