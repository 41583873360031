import {useNavigate} from "react-router-dom";
import {isPc, sendRequest} from "../static/js/common";
import {useEffect, useState} from "react";
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';

export const Footer = ({}) => {

  const navigate = useNavigate();

   const menu = (
      <Menu
        style={{ width: 110, border: 'none' }}
      >
        <MenuItem key="1" className={'select-menu cursor'} onClick={() => window.open('https://www.interworksmedia.co.kr/interview/')}>광고주</MenuItem>
        <MenuItem key="2" className={'select-menu cursor'} onClick={() => window.open('https://www.interworksmedia.co.kr/media/')}>매체사</MenuItem>
      </Menu>
    );

  const [brochureUrl, setBrochureUrl] = useState("");
  const [adUrl, setAdUrl] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [footerPcText, setFooterPcText] = useState("");
  const [footerMobileText, setFooterMobileText] = useState("");
  const [copyright, setCopyright] = useState("");

  const getBrochure = () => {
    sendRequest('config/config/?key=BROCHURE', 'GET', null, data => {
      setBrochureUrl(data?.[0]?.file)
    })
  }

  const getUrls = () => {
    sendRequest('config/config/?key=광고주URL', 'GET', null, data => {
      setAdUrl(data?.[0]?.value)
    })
    sendRequest('config/config/?key=매체사URL', 'GET', null, data => {
      setMediaUrl(data?.[0]?.value)
    })
  }

  const getFooterText = () => {
    sendRequest('config/config/?key=footerPc', 'GET', null, data => {
      setFooterPcText(data?.[0]?.desc)
    })
    sendRequest('config/config/?key=footerMobile', 'GET', null, data => {
      setFooterMobileText(data?.[0]?.desc)
    })
  }

  const getCopyright = () => {
    sendRequest('config/config/?key=copyright', 'GET', null, data => {
      setCopyright(data?.[0]?.desc)
    })
  }

  useEffect(() => {
    getBrochure()
    getUrls()
    getFooterText()
    getCopyright()
  }, [])

  return (
    <div className={'footer'}>
      {isPc ?
        <div className={'flex-row'}>
          <div className={'flex-1 flex-column'} style={{padding: '2vw', paddingBottom: '4vw'}}>
            <div className={'white font-40 poppins-black'}>INTERWORKS</div>
            <div className={'white font-14 margin-top-20 pre-wrap'} style={{lineHeight: 1.3}}>
              {footerPcText}
            </div>
            <div className={'blur-white font-14 margin-top-20'}>
              {copyright}
            </div>
          </div>

          {/*<div className={'flex-end flex-column'}>*/}
          {/*  <div className={'flex-end'} style={{paddingRight: '2vw'}} onClick={() => window.open(brochureUrl)}>*/}
          {/*    <div className={'brochure'}>COMPANY BROCHURE</div>*/}
          {/*  </div>*/}
            {/*<div className={'relative margin-top-54'} onClick={() => navigate('/contact')}>*/}
            {/*  <div className={'absolute white bold notosans-black font-24'} style={{left: 0, bottom: '1vw'}}>문의하기</div>*/}
            {/*  <img style={{width: '30vw'}} src={require('../assets/images/footer_line.png')}/>*/}
            {/*</div>*/}
          {/*</div>*/}
      </div>
      :
        <div className={'width-100'} style={{padding: '120px 14vw 20vw 3vw'}}>
          <div className={'white font-40 poppins-black'}>INTERWORKS</div>
          <div className={'white font-10 margin-top-10 pre-wrap'} style={{lineHeight: 1.8}}>
            {footerMobileText}
          </div>

          {/*<div className={'margin-top-54 flex-end'} onClick={() => navigate('/contact')} style={{paddingRight: '3vw', marginTop: '25vw'}}>*/}
          {/*  <img style={{width: '80vw'}} src={require('../assets/images/bottom_contact.png')}/>*/}
          {/*</div>*/}

          <div className={'align-center flex-column margin-top-54'} style={{marginLeft: '-8vw', marginTop: '20vw', marginBottom: '10vw'}}>
            <div className={'flex-row'}>
              {/*<div className={''} style={{paddingRight: '2vw'}} onClick={() => window.open(brochureUrl)}>*/}
              {/*  <div className={'brochure'}>COMPANY BROCHURE</div>*/}
              {/*</div>*/}
              {/*<div className={'select-box cursor notosans-regular flex-row font-14'} style={{marginLeft: '0.5vw'}}>*/}
              {/*  <select*/}
              {/*    className={'select font-14 cursor'}*/}
              {/*    defaultValue={'리포트'}*/}
              {/*    onChange={(e) => window.open(e.target.value)}*/}
              {/*  >*/}
              {/*    <option disabled={true}>*/}
              {/*      리포트*/}
              {/*      <img className={'icon-12 margin-left-8'} src={require('../assets/images/arrow_down.png')}/>*/}
              {/*    </option>*/}
              {/*    <option value={adUrl}>광고주</option>*/}
              {/*    <option value={mediaUrl}>매체사</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
            </div>
            <div className={'more-blur-white font-12 margin-top-20'}>
              {copyright}
            </div>
          </div>
        </div>
      }

    </div>
  )
}
