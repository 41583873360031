import {Header} from "../components/Header";
import {Slide} from "react-awesome-reveal";
import CountUp from 'react-countup';
import {Footer} from "../components/Footer";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {useEffect, useRef, useState} from "react";
import {isIOS, isPc, sendRequest} from "../static/js/common"; // requires a loader
import '../static/css/main.css'


export const Publisher = ({}) => {

  const carouselRef = useRef(null);
  const scrollRef = useRef(null)

  const [type, setType] = useState("All Device");
  const [partnershipImage, setPartnershipImage] = useState('');
  const [mobilePartnershipImage1, setMobilePartnershipImage1] = useState('');
  const [mobilePartnershipImage2, setMobilePartnershipImage2] = useState('');
  const [deviceImages, setDeviceImages] = useState([]);
  const [formatImages, setFormatImages] = useState([]);
  const [productUrl, setProductUrl] = useState()

  const partners = [
    'Publisher Partner',
    '3rd Party Partner',
  ]

  const [partner, setPartner] = useState(partners[0]);

  const getPartnershipImage = () => {
    sendRequest('publisher/partnership/?key=pc', 'GET', null, data =>{
      setPartnershipImage(data?.[0]?.image)
    })
  }

  const getMobilePartnershipImage = () => {
    sendRequest('publisher/partnership/?key=mobile1', 'GET', null, data =>{
      setMobilePartnershipImage1(data?.[0]?.image)
    })

    sendRequest('publisher/partnership/?key=mobile2', 'GET', null, data =>{
      setMobilePartnershipImage2(data?.[0]?.image)
    })
  }

  const getDeviceImages = () => {
    sendRequest('publisher/device/', 'GET', null, data =>{
      if(isIOS()) {
        setDeviceImages(data?.filter(item => item?.key === 'ios'))
      } else {
        setDeviceImages(data?.filter(item => item?.key === 'pc'))
      }
    })
  }

  const getFormatImages = () => {
    sendRequest('publisher/format/', 'GET', null, data =>{
      if(isIOS()) {
        setFormatImages(data?.filter(item => item?.key === 'ios'))
      } else {
        setFormatImages(data?.filter(item => item?.key === 'pc'))
      }

    })
  }

  const types = [
    'All Device',
    'All Format'
  ]

  const getProduct = () => {
    sendRequest('config/config/?key=퍼블리셔상품소개서', 'GET', null, data => {
      setProductUrl(data?.[0]?.file)
    })
  }

  useEffect(() => {
    window.scrollTo(0,0);
    if(isPc) {
      getPartnershipImage()
    } else {
      getMobilePartnershipImage()
    }
    getDeviceImages()
    getFormatImages()
    getProduct()
  }, [])

  return (
    <div>
      <Header/>
      {isPc ?
        <div ref={scrollRef} className={'pc-container scroll-snap'} style={{marginTop: 80}}>
          <div className={'relative section height-100vh'}>
            <video
              playsInline
              autoPlay
              muted
              loop
              className={'key-video'}
              src={require('../assets/videos/sub2_keyvisual.mp4')}
            />
            <div className={'absolute flex-center flex-column'} style={{top: '38vh', left: 0, width: '100%'}}>
              <div className={'poppins-black darkgray main-title'} style={{lineHeight: 1.1}}>PUBLISHER</div>
              <div className={'darkgray font-30'}>Optimization / Google Partnership / Client</div>
              {/*<div style={{marginTop: '18vw'}}>*/}
              {/*  <img style={{width: 80}} src={require('../assets/images/play_down.png')}/>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className={'relative white-bg section height-100 width-100-vw'} style={{marginTop: 0}}>
            <img className={'width-100 container-img'} src={require('../assets/images/publisher1_bg.png')}/>
            <div className={'absolute align-center flex-column width-100'} style={{top: '10vh'}}>
              <div className={'darkgray notosans-black title'}>퍼블리셔를 위한 애드 네트워크</div>
              <div className={'darkgray text-center font-24 margin-top-28'} style={{lineHeight: 1.8}}>
                다년간 축적된 노하우를 바탕으로 국내•외<br/>
                다양한 네트워크사들과 연동하여 퍼블리셔에게 양질의 광고를 제공하고 있습니다.
              </div>
            </div>
            <div className={'absolute'} style={{left: '11vw', bottom: '18vh'}}>
              <Slide direction={'left'}>
                <div>
                  <div className={'flex-row'}>
                    <CountUp end={800} delay={1} className={'gray poppins-semibold'} style={{fontSize: '7vw'}}/>
                    <img style={{width: '7vw'}} src={require('../assets/images/up_arrow.gif')}/>
                  </div>
                  <div className={'darkgray poppins-semibold'}
                       style={{fontSize: '1.8vw', marginTop: '-1.5vw', marginLeft: '0.8vw'}}>PUBLISHERS
                  </div>
                  <div className={'darkgray notosans-light text-center margin-top-20'}
                       style={{marginRight: '7vw', fontSize: '2.2vh'}}>약 800여 개 퍼블리셔 제휴
                  </div>
                </div>
              </Slide>
            </div>
            <div className={'absolute'} style={{left: '39vw', bottom: '18vh'}}>
              <Slide direction={'up'}>
                <div>
                  <div className={'flex-row'}>
                    <CountUp end={5000} delay={2} className={'gray poppins-semibold'} style={{fontSize: '7vw'}}/>
                    <img style={{width: '7vw'}} src={require('../assets/images/up_arrow.gif')}/>
                  </div>
                  <div className={'darkgray poppins-semibold'}
                       style={{fontSize: '1.8vw', marginTop: '-1.5vw', marginLeft: '0.8vw'}}>MILLION REQUESTS
                  </div>
                  <div className={'darkgray notosans-light text-center margin-top-20'}
                       style={{marginRight: '7vw', fontSize: '2.2vh'}}>약 50억 광고 트래픽 운영
                  </div>
                </div>
              </Slide>
            </div>
            <div className={'absolute'} style={{right: '4vw', bottom: '18vh'}}>
              <Slide direction={'right'}>
                <div>
                  <div className={'flex-row'}>
                    <CountUp end={3000} delay={3} className={'gray poppins-semibold'} style={{fontSize: '7vw'}}/>
                    <img style={{width: '7vw'}} src={require('../assets/images/up_arrow.gif')}/>
                  </div>
                  <div className={'darkgray poppins-semibold'}
                       style={{fontSize: '1.8vw', marginTop: '-1.5vw', marginLeft: '0.8vw'}}>MILLION IMPRESSIONS
                  </div>
                  <div className={'darkgray notosans-light text-center margin-top-20'}
                       style={{marginRight: '7vw', fontSize: '2.2vh'}}>약 30억 광고 노출
                  </div>
                </div>
              </Slide>
            </div>
          </div>

          <div className={'relative width-100 section height-100 width-100-vw'} style={{marginTop: 0, backgroundColor: '#f1f1f1'}}>
            <div className={''}>
              <img className={'container-img'} src={require('../assets/images/publisher3_bg.png')}/>
              <div className={'absolute'} style={{top: '24dvh', left: '10vw'}}>
                {(type === 'All Device' && deviceImages?.length) ?
                  <Carousel
                    ref={carouselRef}
                    swipeable={true}
                    emulateTouch={true}
                    showThumbs={false}
                    showArrows={false}
                    showIndicators={false}
                    renderIndicator={(clickHandler, isSelected, index) => {
                      return (
                        <span key={`dot-${index}`} className={`dot`}
                              style={{backgroundColor: isSelected ? '#3E3E3E' : '#B5B5B5', borderWidth: 0}}/>
                      )
                    }}
                    showStatus={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    centerMode={true}
                    centerSlidePercentage={100}
                    width={'40vw'}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    animationHandler={'slide'}
                    interval={3000}
                  >
                    {deviceImages?.map((item, index) => {
                      return (
                        <div style={{marginBottom: '5vw'}}>
                          <video
                          playsInline
                          autoPlay
                          muted
                          loop
                          key={`device-${item?.id}-${index}`}
                          className={'publisher-image'}
                          src={item?.video}/>
                        </div>
                      )
                    })}
                </Carousel>
                : formatImages?.length ?
                <Carousel
                    ref={carouselRef}
                    swipeable={true}
                    emulateTouch={true}
                    showThumbs={false}
                    showArrows={false}
                    showIndicators={false}
                    renderIndicator={(clickHandler, isSelected, index) => {
                      return (
                        <span key={`dot-${index}`} className={`dot`}
                              style={{backgroundColor: isSelected ? '#3E3E3E' : '#B5B5B5', borderWidth: 0}}/>
                      )
                    }}
                    showStatus={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    centerMode={true}
                    centerSlidePercentage={100}
                    width={'40vw'}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    animationHandler={'slide'}
                    interval={3000}
                  >
                    {formatImages?.map((item, index) => {
                        return (
                          <div style={{marginBottom: '0.1vw'}}>
                            <video
                            playsInline
                            autoPlay
                            muted
                            loop
                            key={`format-${item?.id}-${index}`}
                            className={'publisher-image'}
                            src={item?.video}
                            />
                          </div>
                        )
                      })
                    }
                </Carousel>
                : null
            }
            </div>
            <div className={'absolute'} style={{top: '20dvh', left: '54vw'}} align={'left'}>
                <div className={'darkgray font-20 poppins-semibold'}>All Device & Format</div>
                <div className={'notosans-black sub-title'}>
                  다양한 디바이스,<br/>
                  다양한 포맷 상품지원
                </div>
                <div className={'font-20 margin-top-32 poppins-medium device-text'}>
                  {type === 'All Device' ? 'PC \u00A0\u00A0| \u00A0\u00A0Mobile \u00A0\u00A0| \u00A0\u00A0Tablet' : 'Video \u00A0\u00A0|\u00A0\u00A0 Banner \u00A0\u00A0|\u00A0\u00A0 Interstitial & Reward \u00A0\u00A0|\u00A0\u00A0 Native'}
                </div>
                <div className={'flex-row flex-grow'} style={{marginTop: '8vh'}}>
                  {types?.map((item, index) => {
                    const isActive = item === type
                    return (
                      <div
                        className={`width-160px adproduct-menu ${isActive ? 'active-menu' : ''} cursor`}
                        style={{marginLeft: 0, marginRight: 9}}
                        onClick={() => {
                          setType(item)
                          if (item === 'All Device') {
                            carouselRef?.current?.moveTo(0)
                          } else {
                            carouselRef?.current?.moveTo(0)
                          }
                        }}
                      >
                        {item}
                      </div>
                    )
                  })}
                </div>
                <div style={{marginTop: '4vh'}}>
                  <div className={'font-18 notosans-bold underline cursor'} onClick={() => window.open(productUrl)}>상품소개서 보러가기↗</div>
                </div>
              </div>
            </div>
          </div>

          <div className={'relative section width-100-vw height-100'} style={{marginTop: 0, backgroundColor: '#efefef'}}>
            <video playsInline muted autoPlay loop className={'key-video'} style={{}} src={require('../assets/videos/sub_02_03_google.mp4')}/>
            <div className={'absolute'} style={{top: '18.5vh', left: '7vw'}}>
              <div className={'white poppins-black font-20'}>Partner</div>
              <div className={'white notosans-black sub-title'}>구글 공식</div>
              <div className={'white notosans-black sub-title'}>채널 파트너사</div>
              <div className={'white font-22'} style={{lineHeight: 1.8}}>
                Google MCM 공식 채널 파트너로서 <br/>
                퍼블리셔의 광고 최적화 및 수익 증대에 기여하여,<br/>
                2023년 구글 공식 퍼블리싱 파트너인 GCPP로 선정되었습니다.
              </div>
              <img style={{width: '20vw', marginTop: '8vh'}} src={require('../assets/images/mobile/google_badge.png')}/>
            </div>
          </div>

          <div className={'relative white-bg section width-100-vw'} style={{marginTop: 0, height: 'calc(100dvh - 70px)'}}>
            <img className={'container-img'} src={require('../assets/images/main4_bg.png')}/>
            <div className={'absolute width-100'} style={{top: 0, left: 0}}>
              <div className={'flex-center flex-column width-100'} style={{paddingTop: '8vh'}}>
                <div className={'notosans-black sub-title'}>전략적 파트너십을 통한 다양한 인벤토리 확보</div>
                <div className={'font-22 notosans-regular margin-top-20 text-center padding-horizontal-38'} style={{lineHeight: 1.8}}>
                  인터웍스미디어는 퍼블리셔들에게 유니크한 자사 광고 및 다양한 3rd party 업체와의 연동을 통해 인벤토리 가치를 극대화합니다.
                </div>
              </div>
              <div className={'flex-center flex-column width-100'} style={{marginTop: '5vh'}}>
                <div style={{marginTop: '1vh'}}>
                  <img className={'partnership-image'} src={partnershipImage}/>
                </div>
              </div>
            </div>
          </div>

          <div className={'section'}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{right: 27, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => scrollRef?.current?.scrollTo(0, 0)}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
        :
        <div ref={scrollRef} className={'mobile-container mobile-wrapper'} style={{marginTop: 80}}>
          <div className={'relative section white-bg'}>
            <video
              playsInline
              autoPlay
              muted
              loop
              className={'width-100 height-100vh'}
              style={{objectFit: 'cover'}}
              src={require('../assets/videos/m_sub_02_01_publisher.mp4')}
            />
            <div className={'absolute flex-center flex-column width-100 height-100vh'} style={{top: 0, left: 0}}>
              <div className={'poppins-black darkgray'} style={{fontSize: '13vw', lineHeight: 1.1}}>PUBLISHER</div>
              <div className={'darkgray font-14'}>Optimization / Google Partnership / Client</div>
            </div>
          </div>

          <div className={'relative white-bg flex-1 section mobile-height-100vh'} style={{marginTop: -5}}>
            <img className={'width-100 mobile-height-100vh'}
                 src={require('../assets/images/mobile/publisher2_bg.png')}/>
            <div className={'absolute width-100 mobile-height-100vh flex-center flex-column'}
                 style={{top: -80, left: 0}}>
              <div className={'darkgray notosans-black text-center'} style={{fontSize: '10vw', marginTop: '0vw'}}>퍼블리셔를
                위한<br/> 애드 네트워크
              </div>
              <div className={'darkgray text-center font-16 margin-top-20'}>
                다년간 축적된 노하우를 바탕으로<br/>
                국내•외 다양한 네트워크사들과 연동하여<br/>
                퍼블리셔에게 양질의 광고를 제공하고 있습니다.
              </div>
            </div>
          </div>

          <div className={'relative white-bg flex-1 section mobile-height-100vh'} style={{marginTop: -5}}>
            <img className={'width-100 mobile-height-100vh'}
                 src={require('../assets/images/mobile/publisher2_bg.png')}/>
            <div className={'absolute width-100 flex-center'} style={{left: '6vw', top: '5vh'}} align={'center'}>
              <Slide direction={'left'}>
                <div>
                  <div className={'flex-row'}>
                    <CountUp end={800} delay={1} className={'gray poppins-semibold'} style={{fontSize: '20vw'}}/>
                    <img style={{width: '12vw'}} src={require('../assets/images/up_arrow.gif')}/>
                  </div>
                  <div className={'darkgray poppins-semibold text-center'}
                       style={{fontSize: '6vw', marginTop: '-8vw', marginLeft: '-10vw'}}>
                    PUBLISHERS
                  </div>
                  <div className={'darkgray notosans-light text-center font-20'} style={{marginLeft: '-6vw'}}>
                    약 800여 개 퍼블리셔 제휴
                  </div>
                </div>
              </Slide>
            </div>
            <div className={'absolute width-100 flex-center'} style={{left: '5vw', top: '28vh'}}>
              <Slide direction={'up'}>
                <div>
                  <div className={'flex-row'}>
                    <CountUp end={5000} delay={1} className={'gray poppins-semibold'} style={{fontSize: '20vw'}}/>
                    <img style={{width: '12vw'}} src={require('../assets/images/up_arrow.gif')}/>
                  </div>
                  <div className={'darkgray poppins-semibold text-center'}
                       style={{fontSize: '6vw', marginTop: '-6vw', marginLeft: '-5vw'}}>
                    MILLION REQUESTS
                  </div>
                  <div className={'darkgray notosans-light text-center font-20'} style={{marginLeft: '-5vw'}}>
                    약 50억 광고 트래픽 운영
                  </div>
                </div>
              </Slide>
            </div>
            <div className={'absolute width-100 flex-center'} style={{left: '5vw', top: '51vh'}}>
              <Slide direction={'right'}>
                <div>
                  <div className={'flex-row'}>
                    <CountUp end={3000} delay={1} className={'gray poppins-semibold'} style={{fontSize: '20vw'}}/>
                    <img style={{width: '12vw'}} src={require('../assets/images/up_arrow.gif')}/>
                  </div>
                  <div className={'darkgray poppins-semibold text-center'}
                       style={{fontSize: '6vw', marginTop: '-6vw', marginLeft: '-5vw'}}>
                    MILLION IMPRESSIONS
                  </div>
                  <div className={'darkgray notosans-light text-center font-20'} style={{marginLeft: '-5vw'}}>
                    약 30억 광고 노출
                  </div>
                </div>
              </Slide>
            </div>
          </div>

          <div className={'relative width-100 section mobile-height-100vh'} style={{marginTop: -5}}>
            <div className={'relative'}>
              <img className={'width-100'} src={require('../assets/images/mobile/publisher3_bg.png')}/>
              <div className={'absolute width-100'} style={{top: '15vh', left: 0}} align={'center'}>
                <div className={'darkgray font-14'}>All Device & Format</div>
                <div className={'notosans-black'} style={{fontSize: '10vw', lineHeight: '12vw'}}>
                  다양한 디바이스<br/>
                  포맷 상품지원
                </div>
                <div className={'font-14 margin-top-20 poppins-regular'}>
                  {type === types[0] ? 'PC \u00A0\u00A0| \u00A0\u00A0Mobile \u00A0\u00A0| \u00A0\u00A0Tablet' : 'Video \u00A0\u00A0|\u00A0\u00A0 Banner \u00A0\u00A0|\u00A0\u00A0 Interstitial & Reward \u00A0\u00A0|\u00A0\u00A0 Native'}
                </div>

                <div className={'flex-row align-center width-100'} style={{marginTop: 20}}>
                  {types?.map((item, index) => {
                    const isActive = item === type
                    return (
                      <div
                        className={`mobile-adproduct-menu poppins-semibold ${isActive ? 'active-menu' : ''}`}
                        onClick={() => {
                          setType(item)
                          if (item === 'All Device') {
                            carouselRef?.current?.moveTo(0)
                          } else {
                            carouselRef?.current?.moveTo(0)
                          }
                        }}
                      >
                        {item}
                      </div>
                    )
                  })}
                </div>

                <div className={'width-100 align-center'}
                     style={{marginTop: 40, left: 0}}>
                  <Carousel
                    ref={carouselRef}
                    swipeable={true}
                    emulateTouch={true}
                    showThumbs={false}
                    showArrows={false}
                    showIndicators={false}
                    renderIndicator={(clickHandler, isSelected, index) => {
                      return (
                        <span key={`dot-${index}`} className={`dot`}
                              style={{backgroundColor: isSelected ? '#3E3E3E' : '#B5B5B5', borderWidth: 0}}/>
                      )
                    }}
                    showStatus={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    centerMode={true}
                    centerSlidePercentage={100}
                    width={'100vw'}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    animationHandler={'slide'}
                    interval={3000}
                    // onChange={index => setType(types[index])}
                  >
                    {
                      (type === 'All Device' ? deviceImages : formatImages)?.map((item, index) => {
                        return (
                          <div>
                            <div className={'transparent-bg'}>
                              <video playsInline muted autoPlay loop controls={false}
                                     className={'transparent-bg'}
                                   style={{width: '90vw', objectFit: 'cover'}}
                                   src={item?.video}/>
                            </div>
                            <div style={{marginTop: '8vh'}}>
                              <div className={'font-18 underline cursor text-center'}
                                   onClick={() => window.open(productUrl)}>
                                상품소개서 보러가기↗
                              </div>
                            </div>
                          </div>
                      )
                      })
                    }
                  </Carousel>
                </div>
              </div>
            </div>
          </div>

          <div className={'relative section mobile-height-100vh'} style={{marginTop: -5}}>
            <video playsInline muted autoPlay loop className={'width-100'} style={{objectFit: 'cover'}}
                   src={require('../assets/videos/mobile_google.mp4')}/>
            <div className={'absolute'} style={{top: '14.5vh', left: '7vw'}}>
              <div className={'white poppins-black font-20'}>Partner</div>
              <div className={'white notosans-black'} style={{fontSize: '10vw'}}>구글 공식</div>
              <div className={'white notosans-black'} style={{fontSize: '10vw', marginTop: -6}}>채널 파트너사</div>
              <div className={'white font-16'} style={{lineHeight: 1.8, marginTop: '2vw'}}>
                Google MCM 공식 채널 파트너로서<br/>
                퍼블리셔의 광고 최적화 및 수익 증대에 기여하여,<br/>
                2023년 구글 공식 퍼블리싱 파트너인<br/>
                GCPP로 선정되었습니다.
              </div>
              <img style={{width: '65vw', marginTop: 20}} src={require('../assets/images/mobile/google_badge.png')}/>
            </div>
          </div>

          <div className={'relative white-bg flex-center section mobile-height-100vh'} style={{marginTop: -90}}>
            <img className={'width-100 mobile-height-100vh'}
                 src={require('../assets/images/mobile/publisher5_bg.png')}/>
            <div className={'absolute width-100 align-center flex-column'} style={{top: '10vh', left: 0}}>
              <div className={'notosans-black text-center'} style={{fontSize: '9vw'}}>
                전략적 파트너십을 통한<br/> 다양한 인벤토리 확보
              </div>
              <div className={'font-14 margin-top-10 text-center'} style={{lineHeight: 1.8}}>
                인터웍스미디어는 퍼블리셔들에게 유니크한 자사 광고 및<br/>
                다양한 3rd party 업체와의 연동을 통해 <br/>
                인벤토리 가치를 극대화합니다.
              </div>

              <div className={'partners-wrapper'} style={{width: '84vw'}}>
                <div className={'flex-row'}>
                  {partners?.map((item, index) => {
                    const isActive = item === partner
                    return (
                      <div
                        className={`align-center ${isActive ? 'active-partner-btn' : 'partner-btn'}`}
                        onClick={() => setPartner(item)}
                        style={{
                          backgroundColor: isActive ? '#fff' : '#464646'
                        }}
                      >
                        <div>{item}</div>
                      </div>
                    )
                  })}
                </div>
                <div className={'white-bg'} style={{minHeight: '75vw'}}>
                  <img style={{width: '84vw'}}
                       src={partner === partners[0] ? mobilePartnershipImage1 : mobilePartnershipImage2}/>
                </div>
              </div>
            </div>
          </div>
          <div className={'section'}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{left: 16, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => window?.scrollTo(0, 0)}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
      }

    </div>
  )
}
