import '../static/css/main.css'
import {useState} from "react";

export const Button = ({text, height, backgroundColor, color, borderRadius, border, padding, fontSize, width='100%', onHover, onReverseHover, icon, onClick=()=>{}}) => {

  const [hover, setHover] = useState(false)

  return (
    <div
      className={'button-wrapper cursor'}
      style={{width, height}}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >

      <div className={'button flex-center notosans-bold flex-row'} style={{width, height, backgroundColor: (hover && onReverseHover) ? '#fff' : (hover && onHover) ? '#333' : backgroundColor, color: (hover && onReverseHover) ? '#333' : (hover && onHover) ? '#fff' : color, borderRadius, border, padding, fontSize}}>
        {icon && <div style={{marginRight: 8}}>{icon}</div>}
        {text}
      </div>
    </div>
  )
}
