import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {useEffect, useRef, useState} from "react";
import {Button} from "../modules/Button";
import {isIOS, isPc, SCREEN_WIDTH, sendRequest} from "../static/js/common";
import {Carousel} from "react-responsive-carousel";


const MobileDisplayAd = () => {

  const carouselRef = useRef(null)
  const [displayAds, setDisplayAds] = useState([]);
  const [displayAd, setDisplayAd] = useState(displayAds[0]);

  const getDisplayAd = () => {
    sendRequest('adproduct/display-ad', 'GET', null, data => {
      setDisplayAds(data);
      setDisplayAd(data?.[0])
    })
  }

  useEffect(() => {
    getDisplayAd()
  }, []);

  return (
    <div className={'relative white-bg flex-1 height-100'}>
      <img className={'absolute width-100 height-100'} style={{top: 0, left: 0}}
           src={require('../assets/images/mobile/adproduct5_bg.png')}/>
      <Carousel
        ref={carouselRef}
        swipeable={true}
        emulateTouch={true}
        showThumbs={false}
        showArrows={false}
        showIndicators={true}
        renderIndicator={(clickHandler, isSelected, index) => {
          return (
            <span key={`dot-${index}`} className={`dot`}
                  style={{backgroundColor: isSelected ? '#3E3E3E' : '#B5B5B5', borderWidth: 0}}/>
          )
        }}
        showStatus={false}
        autoPlay={false}
        infiniteLoop={false}
        centerMode={true}
        centerSlidePercentage={100}
        width={'100vw'}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        animationHandler={'slide'}
        interval={3000}
      >
        <div key={'mobile-target-0'}
             className={'align-center flex-column width-100 mobile-da-wrapper'}
             style={{backgroundColor: 'transparent', marginTop: '5vh'}}>
          <div className={'darkgray poppins-black text-center'}
               style={{fontSize: '12vw', lineHeight: 1.1, marginTop: 0}}>DISPLAY AD
          </div>
          <div className={'darkgray margin-top-20 text-center font-16'} style={{lineHeight: 1.6}}>
            국내 메이저 언론사 및 다양한 매체 제휴로<br/>
            넓은 커버리지와 다양한 사이즈를 보유하고 있습니다.
            {/*불법 사이트 등의 유해 매체는 미제휴되어 있으며<br/>*/}
            {/*매체 화이트리스팅 가능합니다.*/}
          </div>
        </div>
        {displayAds?.map((item, index) => {
          return (
            <div key={`mobile-target-${index + 1}`}
                 className={'flex-column width-100 mobile-da-wrapper'} style={{marginTop: '5vh'}}>
              <div className={'darkgray notosans-black margin-top-10 text-center margin-top-40'}
                   style={{fontSize: '10vw'}}>
                {item?.name}
              </div>
              <div className={'pre-wrap margin-top-8 font-14 gray'}>
                {item?.desc}
              </div>
              <div className={'margin-top-10'}>
                <div onClick={() => window.open(isPc ? item?.link : item?.mobileLink)}
                     className={'font-12 underline cursor'}>샘플 보러가기↗
                </div>
              </div>
              {item?.videoPc ?
                <div className={'flex-row width-100 height-100 align-center'} style={{paddingBottom: '10vh'}}>
                  <div className={'relative'}>
                    <img src={require('../assets/images/pc_frame.png')} className={'absolute'}
                         style={{width: '48vw', top: 0, left: 0}}/>
                    <video playsInline muted autoPlay loop className={'width-100'}
                           style={{width: '48vw', objectFit: 'cover', borderRadius: '2vw'}}
                           src={item?.videoPc}/>
                  </div>
                  <div className={'relative'} style={{marginLeft: '1vw'}}>
                    <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}
                         style={{width: '28vw', top: 0, left: 0}}/>
                    <video playsInline muted autoPlay loop className={'width-100 white-bg'}
                           style={{
                             width: '27.5vw',
                             objectFit: 'cover',
                             marginTop: '0.3vw',
                             borderRadius: '5vw',
                             paddingTop: '3.1vw',
                             paddingBottom: '3.1vw',
                             paddingLeft: '0.6vw',
                             marginLeft: '0.1vw'
                           }}
                           src={item?.videoMobile}/>
                  </div>
                </div>
                :
                item?.imagePc ?
                  <div className={'width-100 height-100 flex-row align-center'} style={{paddingBottom: '10vh'}}>
                    <div className={'relative'}>
                      <img src={require('../assets/images/pc_frame.png')} className={'absolute'}
                           style={{width: '48vw', top: 0, left: 0}}/>
                      <img
                        className={'white-bg no-border'}
                        style={{
                          maxWidth: '48vw',
                          borderRadius: '1vh',
                          objectFit: 'contain',
                          outline: 'none',
                        }}
                        src={item?.imagePc}/>
                    </div>
                    <div className={'relative'} style={{marginLeft: '1vw'}}>
                      <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}
                           style={{width: '28vw', top: 0, left: 0}}/>
                      <img className={'white-bg no-border'} style={{
                        maxWidth: '26.8vw',
                        borderRadius: '2vh',
                        marginLeft: '0.5vw',
                        paddingTop: '3vw',
                        paddingBottom: '3vw',
                        objectFit: 'contain'
                      }} src={item?.imageMobile}/>
                    </div>
                  </div>
                  :
                  <div className={'width-100 height-100 align-center'}>
                    <div className={'relative'} style={{width: '80vw'}}>
                      <img className={'width-100'} src={require('../assets/images/frame.png')}/>
                      <div className={'absolute'} style={{top: '15.48vw', left: '1vw'}}>
                        <video playsInline muted autoPlay loop className={'z-index'}
                               style={{width: '44.7vw', zIndex: 9, borderRadius: '1.2vw'}}
                               src={require('../assets/videos/action_pc.mp4')}/>
                      </div>
                      <div className={'absolute'} style={{top: '0.75vw', right: '1.2vw'}}>
                        <video playsInline muted autoPlay loop className={'z-index'}
                               style={{width: '30vw', zIndex: 9, borderRadius: '4vw', objectFit: 'cover'}}
                               src={require('../assets/videos/action_mobile.mp4')}/>
                      </div>
                    </div>
                  </div>
              }
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

const DisplayAd = () => {

  const [displayAds, setDisplayAds] = useState([]);
  const [displayAd, setDisplayAd] = useState(displayAds[0]);

  const getDisplayAd = () => {
    sendRequest('adproduct/display-ad', 'GET', null, data => {
      setDisplayAds(data);
      setDisplayAd(data?.[0])
    })
  }

  useEffect(() => {
    getDisplayAd()
  }, []);

  return (
    <div className={'relative white-bg section height-100 width-100-vw'} style={{marginTop: 0}}>
      <img className={'container-img '} src={require('../assets/images/adproduct5_bg.png')}/>
      <div className={'absolute flex-center flex-column width-100'} style={{top: '4dvh'}}>
        <div className={'darkgray poppins-black title'}>DISPLAY AD</div>
        <div className={'darkgray text-center font-22'} style={{lineHeight: 1.6, marginTop: '0.1vw'}}>
          국내 메이저 언론사 및 다양한 매체 제휴로<br/>
          넓은 커버리지와 다양한 사이즈를 보유하고 있습니다.
        </div>
        <div className={'width-100 flex-row'} style={{marginTop: '2dvh', height: '60dvh', marginLeft: '-10vw'}}>
          <div className={'flex-1 flex-end'} style={{marginLeft: '5vw'}}>
            <div className={'width-100 height-100 align-center'} style={{justifyContent: 'flex-end'}}>
              <div className={'relative no-scroll'} style={{marginRight: displayAd?.imagePc ? '0.2vw' : 0}}>
                <img src={require('../assets/images/pc_frame.png')} className={'absolute'}
                     style={{width: '35vmin', top: 0, left: 0, overflow: 'hidden'}}/>
                <video key={'displayAd?.videoPc'} playsInline muted autoPlay loop
                       style={{
                         width: '33.9vmin',
                         zIndex: 9,
                         marginLeft: '0.6vmin',
                         marginTop: '0.4vmin',
                         borderRadius: '0.5vmin',
                         display: displayAd?.videoPc ? 'inherit' : 'none',
                         overflow: 'hidden'
                       }}
                       src={displayAd?.videoPc}/>
                <div className={'white-bg relative align-center no-border'} style={{
                  borderRadius: '0.75vmin',
                  paddingTop: '0vmin',
                  paddingBottom: '0vmin',
                  marginTop: '0.7vmin',
                  marginLeft: '0.9vmin',
                  paddingRight: '1vmin',
                  display: displayAd?.videoPc ? 'none' : 'inherit',
                  overflow: 'hidden',
                  paddingLeft: '0vmin'
                }}>
                  <img
                    style={{
                      maxWidth: '32.5vmin',
                      marginTop: '0.1vmin',
                      borderRadius: '2vmin',
                      overflow: 'hidden',
                      marginLeft: '-0.15vmin',
                    }}
                    className={'no-border'}
                    src={displayAd?.imagePc}
                  />
                </div>
              </div>
              <div className={'relative no-scroll'} style={{marginLeft: displayAd?.imagePc ? '1vw' : '1vw', marginRight: displayAd?.imagePc ? '-0.1vw' : 0}}>
                <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}
                     style={{width: '22vmin', top: 0, left: 0, zIndex: 2}}/>
                <div className={'white-bg relative align-center'} style={{
                  borderRadius: '2vmin',
                  paddingTop: '0.8vmin',
                  paddingBottom: '0.8vmin',
                  marginTop: '0.5vmin',
                  marginLeft: '0.5vmin',
                  display: displayAd?.videoMobile ? 'inherit' : 'none',
                  overflow: 'hidden',
                  border: 'none'
                }}>
                  <video key={'displayAd.videoMobile'} playsInline muted autoPlay loop
                         style={{
                           width: '21vmin',
                           zIndex: 9,
                           marginTop: '1.1vmin',
                           marginBottom: '1.1vmin',
                           marginLeft: '0vmin',
                           marginRight: '0vmin',
                           objectFit: 'cover',
                           overflow: 'hidden',
                           border: 'none'
                         }}
                         src={displayAd?.videoMobile}/>
                </div>
                <div className={'white-bg relative align-center'} style={{
                  borderRadius: '3vmin',
                  paddingTop: '1.5vmin',
                  paddingBottom: '1.5vmin',
                  marginTop: '0.6vmin',
                  marginLeft: '0.1vmin',
                  display: displayAd?.videoMobile ? 'none' : 'inherit',
                  overflow: 'hidden',
                  zIndex: 0,
                  border: 'none'
                }}>
                  <img style={{
                    maxWidth: '21.5vmin',
                    marginLeft: '0vmin',
                    borderRadius: '2vmin',
                    overflow: 'hidden',
                    zIndex: -1,
                    border: 'none'
                  }}
                       src={displayAd?.imageMobile}/>
                </div>
              </div>
            </div>
            {/*:*/}
            {/*<div className={'flex-row width-100 height-100 align-center'}*/}
            {/*    style={{marginTop: '-0.5dvh'}}>*/}
            {/*    <div style={{marginLeft: '0vw'}}>*/}
            {/*      <div className={'relative align-center'}>*/}
            {/*        <img src={require('../assets/images/pc_frame.png')} className={'absolute'}*/}
            {/*             style={{width: '24vw', top: 0, left: 0}}/>*/}
            {/*        <video playsInline muted autoPlay loop className={'z-index'}*/}
            {/*               style={{*/}
            {/*                 width: '22.9vw',*/}
            {/*             zIndex: 9,*/}
            {/*             marginLeft: '0.6vw',*/}
            {/*             marginTop: '0.4vw',*/}
            {/*             borderRadius: '0.5vw'*/}
            {/*           }}*/}
            {/*           src={displayAd?.videoPc}/>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div style={{marginLeft: '1.7vw'}}>*/}
            {/*  <div className={'white-bg relative align-center'} style={{*/}
            {/*    borderRadius: '1.95vw',*/}
            {/*    paddingTop: '1.6vw',*/}
            {/*    paddingBottom: '1.6vw',*/}
            {/*  }}>*/}
            {/*    <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}*/}
            {/*         style={{width: '14vw', top: 0, left: -2}}/>*/}
            {/*    <video playsInline muted autoPlay loop className={'z-index'}*/}
            {/*           style={{*/}
            {/*             width: '12.75vw',*/}
            {/*             zIndex: 9,*/}
            {/*             marginTop: '1vw',*/}
            {/*             marginLeft: '0.6vw',*/}
            {/*             objectFit: 'cover'*/}
            {/*           }}*/}
            {/*           src={displayAd?.videoMobile}/>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
          <div className={'flex-column flex-1'} style={{marginLeft: '5vw'}}>
            <div className={'flex-column'}>
              <div className={'flex-row flex-grow'} style={{marginTop: '2vw'}}>
                {displayAds?.map((item, index) => {
                  const isActive = item?.name === displayAd?.name
                  return (
                    <div
                      className={`width-160px adproduct-menu ${isActive ? 'active-menu' : ''} cursor`}
                      onClick={() => setDisplayAd(item)}
                    >
                      {item?.name}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={'flex-column width-100'} style={{marginTop: '5vh', marginLeft: '0.5vw'}}>
              <div className={'flex-column'}>
                <div className={'darkgray notosans-black medium-title'}>{displayAd?.name}</div>
              </div>
              <div className={'pre-wrap darkgray margin-top-10 font-20'}
                   style={{lineHeight: 1.6, paddingRight: '5vw'}}>
                {displayAd?.desc}
              </div>
              <div style={{marginTop: '4vh'}}>
                <div onClick={() => window.open(isPc ? displayAd?.link : displayAd?.mobileLink)}
                     className={'font-18 underline cursor'}>샘플 보러가기↗
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export const ADProduct = ({}) => {

  const carouselRef = useRef(null)
  const scrollRef = useRef(null)
  const [targets, setTargets] = useState([]);
  const [interplays, setInterplays] = useState([]);
  const videoRef = useRef(null);
  const [interplay, setInterplay] = useState(interplays[0]);
  const [target, setTarget] = useState(targets[0]);
  const [exposureMedia, setExposureMedia] = useState('');
  const [productUrl, setProductUrl] = useState('');
  const [caseUrl, setCaseUrl] = useState('');

  const [exposureMedia1, setExposureMedia1] = useState('');
  const [exposureMedia2, setExposureMedia2] = useState('');
  const [exposureMedia3, setExposureMedia3] = useState('');

  const partners = [
    '일간지/경제지',
    '스포츠/연예',
    '전문지/커뮤니티'
  ]

  const [partner, setPartner] = useState(partners[0]);

  const getMobileExposureMedia = () => {
    sendRequest(`adproduct/exposure-media/?key=mobile1`, 'GET', null, data => {
      setExposureMedia1(data?.[0]?.image);
    })

    sendRequest(`adproduct/exposure-media/?key=mobile2`, 'GET', null, data => {
      setExposureMedia2(data?.[0]?.image);
    })

    sendRequest(`adproduct/exposure-media/?key=mobile3`, 'GET', null, data => {
      setExposureMedia3(data?.[0]?.image);
    })
  }


  const getProduct = () => {
    sendRequest('config/config/?key=상품소개서', 'GET', null, data => {
      setProductUrl(data?.[0]?.file)
    })
  }

  const getCase = () => {
    sendRequest('config/config/?key=집행사례', 'GET', null, data => {
      setCaseUrl(data?.[0]?.file)
    })
  }

  const getTargets = () => {
    sendRequest('adproduct/target', 'GET', null, data => {
      setTargets(data);
      setTarget(data?.[0])
    })
  }

  const getInterplays = () => {
    sendRequest('adproduct/interplay', 'GET', null, data => {
      setInterplays(data);
      setInterplay(data?.[0])
    })
  }

  const getExposureMedia = () => {
    sendRequest('adproduct/exposure-media/?key=pc', 'GET', null, data => {
      setExposureMedia(data?.[0]?.image)
    })
  }

  useEffect(() => {
    getProduct()
    getCase()
    getTargets()
    getInterplays()
    if (isPc) {
      getExposureMedia()
    } else {
      getMobileExposureMedia()
    }
  }, [])

  useEffect(() => {
    // videoRef.current.playbackRate = 0.5;
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <Header/>
      {isPc ?
        <div ref={scrollRef} className={'pc-container scroll-snap'}>
          <div className={'relative section height-100vh'}>
            <video
              ref={videoRef}
              playsInline
              autoPlay
              muted
              loop
              className={'key-video'}
              src={require('../assets/videos/sub_01_01_adproduct.mp4')}
            />
            <div className={'absolute flex-center flex-column width-100-vw'} style={{top: '38vh', left: 0}}>
              <div className={'poppins-black gray main-title'} style={{lineHeight: 1.1}}>AD PRODUCT</div>
              <div className={'gray font-30'}>Targeting / Interplay / DA / Reference</div>
              {/*<div style={{marginTop: '18vw'}}>*/}
              {/*  <img style={{width: 80}} src={require('../assets/images/play_down.png')}/>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className={'relative white-bg section height-100'} style={{marginTop: 0}}>
            <img className={'container-img'} src={require('../assets/images/adproduct2_bg.png')}/>
            <div className={'absolute width-100-vw align-center flex-column height-100vh'} style={{top: 0, left: 0}}>
              <div className={'notosans-black text-center title'} style={{lineHeight: 1, color: '#FCFFE8'}}>
                광고주를 위한 애드 네트워크
              </div>
              <div className={'white font-24 text-center'} style={{marginTop: '5vh'}}>
                고도화된 타겟팅 솔루션을 기반으로<br/>
                프리미엄 영역에 아웃스트림 영상 광고와 배너 광고를 노출,<br/>
                광고의 효율적인 퍼포먼스 및 최적화 관리가 가능한 마케팅 플랫폼입니다.
              </div>
            </div>
          </div>

          <div className={'relative white-bg section height-100vh width-100-vw'}>
            <div className={'white-bg align-center flex-column width-100-vw'} style={{paddingTop: '4dvh'}}>
              <div className={'darkgray poppins-black title'}>TARGETING SOLUTION</div>
              <div className={'darkgray margin-top-10 text-center font-22'} style={{lineHeight: 1.6}}>
                브랜드에 최적화한 맞춤 솔루션과<br/>
                고도화된 다양한 타켓팅 서비스를 제공합니다.
              </div>
            </div>
            <div className={'width-100'} style={{marginTop: '6vh'}}>
              <div className={'flex-row flex-grow target-btn-wrapper'} style={{marginLeft: '40vw', paddingTop: '3vh'}}>
                {targets?.map((item, index) => {
                  const isActive = item?.name === target?.name
                  return (
                    <div
                      style={{zIndex: 99999}}
                      className={`width-160px adproduct-menu white-bg ${isActive ? 'active-menu' : ''} cursor`}
                      onClick={() => setTarget(item)}
                    >
                      {item?.name}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={''}>
              <div className={'flex-row-wo-center z-index'} style={{marginLeft: '10vw', marginTop: '-3vh'}}>
                <div className={'align-center'} style={{width: '25vw', marginRight: '5vw', marginTop: '-6vh'}}>
                  {target?.video ?
                    <div className={'relative ad-product-contents target-video'}>
                      <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}
                           style={{width: '25dvmin', top: '3dvmin', left: '10dvmin'}}/>
                      <video playsInline muted autoPlay loop
                             style={{width: '24dvmin', marginLeft: '10.5dvmin', marginTop: '6dvmin', zIndex: 99999999}}
                             src={target?.video}/>
                    </div>
                    : target?.image ?
                      <div className={'flex-1 vertical-center horizontal-end'} style={{height: '35vh', paddingRight: '3vw'}}>
                        <img className={'ad-product-contents'}
                             style={{maxWidth: '19vw', objectFit: 'contain'}} src={target?.image}/>
                      </div>
                      :
                      <div style={{width: '14vw'}}/>
                  }
                </div>
                <div className={'flex-column target-text-wrapper z-index'}
                     style={{marginLeft: '0.5vw', marginTop: '5vh'}}>
                  <div className={'darkgray notosans-black medium-title'}>{target?.title}</div>
                  <div className={'pre-wrap darkgray font-20'}
                       style={{lineHeight: 1.6, whiteSpace: 'pre-wrap', marginLeft: 2, marginTop: '3vh'}}>
                    {target?.desc}
                  </div>
                </div>
                <div className={'absolute relative'}
                     style={{width: '70vw', maxHeight: '60vh', bottom: '-12dvh', zIndex: 0, right: 0}}>
                  <img className={'width-100 relative'} style={{zIndex: 1}}
                       src={require('../assets/images/adproduct3_bg.png')}/>
                </div>
              </div>
            </div>
          </div>

          <div className={'relative white-bg section height-100 width-100-vw'} style={{marginTop: 0}}>
            <img className={'container-img'} style={{height: 'calc(100vh - 0px)'}}
                 src={require('../assets/images/adproduct4_bg.png')}/>
            <div className={'absolute align-center flex-column width-100'} style={{top: '4dvh', left: 0}}>
              <div className={'darkgray poppins-black title'}>INTERPLAY</div>
              <div className={'darkgray margin-top-10 text-center font-22'} style={{lineHeight: 1.6}}>
                인터플레이는 기사 및 콘텐츠 내 인리드 형식으로<br/>
                영상이 확장 재생되는 아웃스트림 영상 광고 상품입니다.
              </div>
              <div className={'width-100 flex-row'} style={{marginTop: '2dvh', height: '60dvh', marginLeft: '-5vw'}}>
                <div className={'flex-1 flex-end'}>
                  {
                    (interplay?.videoPc && interplay?.videoMobile) ?
                      <div className={'flex-row ad-product-contents'}>
                        <div className={'relative'}>
                          <img src={require('../assets/images/pc_frame.png')} className={'absolute'}
                               style={{width: '32vmin', top: 0, left: 0}}/>
                          <video playsInline muted autoPlay loop className={'width-100'}
                                 style={{width: '32vmin', objectFit: 'cover', borderRadius: '2vw'}}
                                 src={interplay?.videoPc}/>
                        </div>
                        <div className={'relative'} style={{marginLeft: '1vw'}}>
                          <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}
                               style={{width: '22vmin', top: 0, left: 0}}/>
                          <video playsInline muted autoPlay loop className={'width-100'}
                                 style={{
                                   width: '22vmin',
                                   height: '42vmin',
                                   objectFit: 'cover',
                                   marginTop: '0.3vmin',
                                   borderRadius: '3vmin'
                                 }}
                                 src={interplay?.videoMobile}/>
                        </div>
                      </div>
                      : interplay?.videoPc ?
                        <div className={'relative ad-product-contents'}>
                          <img src={require('../assets/images/pc_frame.png')} className={'absolute'}
                               style={{width: '30vw', top: 0, left: 0}}/>
                          <video playsInline muted autoPlay loop className={'width-100'}
                                 style={{width: '30vw', objectFit: 'cover', borderRadius: '2vw'}}
                                 src={interplay?.videoPc}/>
                        </div>
                      :
                      <img style={{width: '40vw'}} src={interplay?.image}/>
                  }
                </div>
                <div className={'flex-column flex-1 adproduct-text-wrapper'} style={{marginLeft: '5vw'}}>
                  <div className={'flex-column interplay-btn-wrapper ad-product-text-contents'}>
                    <div className={'flex-1 flex-row flex-grow'} style={{maxWidth: 600}}>
                      {interplays?.map((item, index) => {
                        const isActive = item?.name === interplay?.name
                        return (
                          <div
                            className={`width-160px adproduct-menu ${isActive ? 'active-menu' : ''} cursor`}
                            onClick={() => setInterplay(item)}
                          >
                            {item?.name}
                          </div>
                        )
                      })}
                    </div>
                    {/*<div className={'flex-1 flex-row margin-top-16'}>*/}
                    {/*  {interplays?.slice(3, 6)?.map((item, index) => {*/}
                    {/*    const isActive = item?.name === interplay?.name*/}
                    {/*    return (*/}
                    {/*      <div*/}
                    {/*        className={`width-160px adproduct-menu ${isActive ? 'active-menu' : ''}`}*/}
                    {/*        onClick={() => setInterplay(item)}*/}
                    {/*      >*/}
                    {/*        {item?.name}*/}
                    {/*      </div>*/}
                    {/*    )*/}
                    {/*  })}*/}
                    {/*</div>*/}
                  </div>
                  <div className={'flex-column width-100'} style={{marginTop: '3vh', marginLeft: '0.5vw'}}>
                    <div className={'flex-column'}>
                      <div className={'darkgray poppins-black medium-title'}>{interplay?.name}</div>
                    </div>
                    <div className={'pre-wrap darkgray margin-top-10 font-20 interplay-desc-wrapper'}
                         style={{lineHeight: 1.6, minHeight: 70}}>
                      {interplay?.desc}
                    </div>
                    <div className={''} style={{marginTop: '1vh'}}>
                      <div onClick={() => window.open(isPc ? interplay?.link : interplay?.mobileLink)} className={'font-18 underline cursor'}>샘플 보러가기↗</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DisplayAd/>

          <div className={'relative white-bg section height-100 width-100-vw'} style={{marginTop: 0}}>
            <div className={'width-100 white-bg'}>
              <div className={'flex-center flex-column width-100'} style={{paddingTop: '7dvh'}}>
                <div className={'notosans-black sub-title'}>주요 광고 노출 매체</div>
                <div className={'font-22 margin-top-20 text-center'} style={{lineHeight: 1.8}}>
                  인터웍스미디어는 주요 언론사, 전문지, 스포츠 및 연예지 등<br/>
                  약 250여 개의 제휴 매체를 보유하고 있습니다.<br/>
                  유해 매체 미제휴, 매체 화이트리스팅 가능
                </div>
              </div>
              <div className={'flex-center width-100 flex-column'} style={{marginTop: '8dvh'}}>
                <div>
                  <img className={'exposure-media'} src={exposureMedia}/>
                </div>
                <div className={'flex-row align-center'} style={{marginTop: '5vh'}}>
                  <div>
                    <Button text={'상품소개서 다운 받기'} width={280} height={57} color={'#101010'} backgroundColor={'#fff'}
                            onHover={true}
                            border={'solid 1px #101010'} borderRadius={57} fontSize={20}
                            onClick={() => window.open(productUrl)}/>
                  </div>
                  <div style={{marginLeft: 30}}>
                    <Button text={'집행 사례 다운 받기'} width={280} height={57} color={'#101010'} backgroundColor={'#fff'}
                            onHover={true}
                            border={'solid 1px #101010'} borderRadius={57} fontSize={20}
                            onClick={() => window.open(caseUrl)}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'section'}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{right: 27, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => scrollRef?.current?.scrollTo(0, 0)}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
        :
        <div ref={scrollRef} className={'mobile-container mobile-wrapper'} style={{marginTop: 80}}>
          <div className={'relative'}>
            <video
              ref={videoRef}
              playsInline
              autoPlay
              muted
              loop
              className={'width-100 height-100vh'}
              style={{objectFit: 'cover'}}
              src={require('../assets/videos/m_sub_01_01_adproduct.mp4')}
            />
            <div className={'absolute flex-center flex-column width-100 height-100vh'} style={{top: 0, left: 0}}>
              <div className={'poppins-black gray'} style={{fontSize: '13vw', lineHeight: 1.1}}>AD PRODUCT</div>
              <div className={'gray font-14'}>Targeting / Interplay / DA / Reference</div>
            </div>
          </div>

          <div className={'relative'} style={{marginTop: -5}}>
            <img className={'container-img'} src={require('../assets/images/mobile/adproduct2_bg.png')}/>
            <div className={'absolute width-100 height-100 flex-center flex-column'} style={{top: 0, left: 0}}>
              <div className={'notosans-black text-center'}
                   style={{fontSize: '10vw', lineHeight: 1.1, color: '#FCFFE8'}}>
                광고주를 위한<br/>
                애드 네트워크
              </div>
              <div className={'white font-16 text-center'} style={{marginTop: '12vw'}}>
                고도화된 타겟팅 솔루션을 기반으로<br/>
                프리미엄 영역에 아웃스트림 영상 광고와<br/>
                배너 광고를 노출,<br/>
                광고의 효율적인 퍼포먼스 및 최적화 관리가<br/>
                가능한 마케팅 플랫폼입니다.
              </div>
            </div>
          </div>

          <div className={'white-bg flex-1'} style={{marginTop: -10, paddingBottom: '5vh'}}>
            <Carousel
              ref={carouselRef}
              swipeable={true}
              emulateTouch={true}
              showThumbs={false}
              showArrows={false}
              showIndicators={true}
              renderIndicator={(clickHandler, isSelected, index) => {
                return (
                  <span key={`dot-${index}`} className={`dot`}
                        style={{backgroundColor: isSelected ? '#3E3E3E' : '#B5B5B5', borderWidth: 0}}/>
                )
              }}
              showStatus={false}
              autoPlay={false}
              infiniteLoop={false}
              centerMode={true}
              centerSlidePercentage={100}
              width={'100vw'}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={50}
              animationHandler={'slide'}
              interval={3000}
            >
              <div key={'mobile-target-0'}
                   className={'white-bg align-center flex-column width-100 mobile-target-wrapper'} style={{marginTop: '5vh'}}>
                <div className={'darkgray poppins-black text-center'}
                     style={{fontSize: '12vw', lineHeight: 1.1, marginTop: 0}}>TARGETING SOLUTION
                </div>
                <div className={'darkgray margin-top-20 text-center font-16'} style={{lineHeight: 1.6}}>
                  브랜드에 최적화한 맞춤 솔루션과<br/>
                  고도화된 다양한 타켓팅 서비스를 제공합니다.
                </div>
              </div>
              {targets?.map((item, index) => {
                return (
                  <div key={`mobile-target-${index + 1}`} style={{marginBottom: '7vh', marginTop: '5vh'}}
                       className={'white-bg flex-column width-100 mobile-target-wrapper'}>
                    <div className={'darkgray notosans-black margin-top-10 text-center margin-top-40'}
                         style={{fontSize: '10vw'}}>
                      {item?.title}
                    </div>
                    <div className={'pre-wrap margin-top-8 font-14 gray'}>
                      {item?.desc}
                    </div>
                    {item?.video ?
                      <div className={'relative width-100 height-100 align-center'}>
                        <div className={'relative'} style={{marginTop: '3vh'}}>
                          <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}
                               style={{height: '37vh', top: '-2vh', left: 0, width: 'auto'}}/>
                          <video playsInline muted autoPlay loop style={{height: '33vh', marginTop: '2vw', marginLeft: '1.1vw'}}
                                 src={item?.video}/>
                        </div>
                      </div>
                      :
                      <div className={'width-100 height-100 align-center'} style={{marginTop: '3vh', paddingBottom: 50}}>
                        <img style={{maxWidth: '70vw', maxHeight: '40vh'}} src={item?.image}/>
                      </div>
                    }
                  </div>
                )
              })}
            </Carousel>
          </div>

          <div className={'relative white-bg flex-1 height-100vh'}>
            <img className={'absolute width-100 container-img'} style={{top: 0, left: 0}}
                 src={require('../assets/images/mobile/adproduct4_bg.png')}/>
            <Carousel
              ref={carouselRef}
              swipeable={true}
              emulateTouch={true}
              showThumbs={false}
              showArrows={false}
              showIndicators={true}
              renderIndicator={(clickHandler, isSelected, index) => {
                return (
                  <span key={`dot-${index}`} className={`dot`}
                        style={{backgroundColor: isSelected ? '#3E3E3E' : '#B5B5B5', borderWidth: 0}}/>
                )
              }}
              showStatus={false}
              autoPlay={false}
              infiniteLoop={false}
              centerMode={true}
              centerSlidePercentage={100}
              width={'100vw'}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={50}
              animationHandler={'slide'}
              interval={3000}
            >
              <div key={'mobile-target-0'}
                   className={'align-center flex-column width-100 mobile-target-wrapper'}
                   style={{backgroundColor: 'transparent', marginTop: '5vh'}}>
                <div className={'darkgray poppins-black text-center'}
                     style={{fontSize: '12vw', lineHeight: 1.1, marginTop: 0}}>INTERPLAY
                </div>
                <div className={'darkgray margin-top-20 text-center font-16'} style={{lineHeight: 1.6}}>
                  인터플레이는 기사 및 콘텐츠 내<br/>
                  인리드 형식으로 영상이 확장 재생되는<br/>
                  아웃스트림 영상 광고 상품입니다.
                </div>
              </div>
              {interplays?.map((item, index) => {
                return (
                  <div key={`mobile-target-${index + 1}`}
                       className={'flex-column width-100 mobile-target-wrapper'} style={{marginTop: '5vh'}}>
                    <div className={'darkgray poppins-black margin-top-10 text-center margin-top-40'}
                         style={{fontSize: '10vw'}}>
                      {item?.name}
                    </div>
                    <div className={'pre-wrap margin-top-8 font-14 gray'}>
                      {item?.desc}
                    </div>
                    <div className={'margin-top-10'}>
                      <div onClick={() => window.open(isPc ? item?.link : item?.mobileLink)} className={'font-12 underline cursor'}>샘플 보러가기↗</div>
                    </div>
                    <div className={'width-100 height-100 align-center'} style={{paddingBottom: 50}}>
                      {(item?.videoPc && item?.videoMobile) ?
                        <div className={'flex-row'}>
                          <div className={'relative'}>
                            <img src={require('../assets/images/pc_frame.png')} className={'absolute'} style={{width: '48vw', top: 0, left: 0}}/>
                            <video playsInline muted autoPlay loop className={'width-100'}
                                   style={{width: '48vw', objectFit: 'cover', borderRadius: '2vw'}}
                                   src={item?.videoPc}/>
                          </div>
                          <div className={'relative'} style={{marginLeft: '1vw'}}>
                            <img src={require('../assets/images/mobile_frame.png')} className={'absolute'}
                                 style={{width: '28vw', top: 0, left: 0}}/>
                            <video playsInline muted autoPlay loop className={'width-100'}
                                   style={{width: '28vw', height: '54vw', objectFit: 'cover', marginTop: '0.3vw', borderRadius: '5vw'}}
                                   src={item?.videoMobile}/>
                          </div>
                        </div>
                        : item?.videoPc ?
                          <div className={'relative'} style={{marginTop: '0vh'}}>
                            <img src={require('../assets/images/pc_frame.png')} className={'absolute'}
                                 style={{width: '60vw', top: 0, left: 0}}/>
                            <video playsInline muted autoPlay loop className={'width-100'}
                                   style={{width: '60vw', objectFit: 'cover', borderRadius: '2vw'}}
                                   src={item?.videoPc}/>
                          </div>
                        :
                        <img style={{width: '40vw'}} src={item?.image}/>
                      }
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>

          <MobileDisplayAd/>

          <div className={'flex-1 flex-column'} style={{backgroundColor: '#E9E9E9'}}>
            <div className={'align-center flex-column width-100'} style={{marginTop: '20vw'}}>
              <div className={'notosans-black'} style={{fontSize: '10vw'}}>주요 광고 노출 매체</div>
              <div className={'font-14 margin-top-20 text-center'} style={{lineHeight: 1.8}}>
                인터웍스미디어는 주요 언론사, 전문지, 스포츠 및 연예지 등<br/>
                약 250여 개의 제휴 매체를 보유하고 있습니다.<br/>
                유해 매체 미제휴, 매체 화이트리스팅 가능
              </div>
            </div>

            <div className={'partners-wrapper'}>
              <div className={'flex-row'}>
                {partners?.map((item, index) => {
                  const isActive = item === partner
                  return (
                    <div
                      className={`align-center ${isActive ? 'active-partner-btn' : 'partner-btn'}`}
                      onClick={() => setPartner(item)}
                      style={{
                        backgroundColor: isActive ? '#fff' : index === 1 ? '#646464' : '#464646'
                      }}
                    >
                      <div>{item}</div>
                    </div>
                  )
                })}
              </div>
              <div className={'white-bg'} style={{padding: '10vw', minHeight: '20vh'}}>
                <img style={{width: '100%'}}
                     src={partner === partners[0] ? exposureMedia1 : partner === partners[1] ? exposureMedia2 : exposureMedia3}/>
              </div>
            </div>
            <div className={'flex-column align-center'} style={{paddingTop: '6vh', paddingBottom: '10vh'}}>
              <div>
                <Button text={'상품소개서 다운 받기'} width={280} height={57} color={'#101010'} backgroundColor={'#fff'}
                        onHover={true}
                        border={'solid 1px #101010'} borderRadius={57} fontSize={20}
                        onClick={() => window.open(productUrl)}/>
              </div>
              <div style={{marginTop: 30}}>
                <Button text={'집행 사례 다운 받기'} width={280} height={57} color={'#101010'} backgroundColor={'#fff'}
                        onHover={true}
                        border={'solid 1px #101010'} borderRadius={57} fontSize={20}
                        onClick={() => window.open(caseUrl)}/>
              </div>
            </div>
          </div>

          {/*<div className={'relative white-bg mobile-height-100vh'} style={{marginTop: -10}}>*/}
          {/*  */}
          {/*</div>*/}

          <div className={'section'}>
            <Footer/>
          </div>
          <div className={'fixed cursor'} style={{left: 16, bottom: 20, zIndex: 99, opacity: 0.8}}
               onClick={() => {window.scrollTo(0,0)}}>
            <img className={'icon-42'} src={require('../assets/images/mobile/go_start.png')}/>
          </div>
        </div>
      }
    </div>
  )
}
